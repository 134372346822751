import React, { Fragment, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CustomFieldStoreView from "./customField/form";
import CustomFieldListView from "./customField/list";
import { Fab, Tooltip, withStyles } from '@material-ui/core';
import LoadingSpinner from '../../containers/LoadingSpinner';

const CustomFieldManager = () => {

    const [onForm, setOnForm] = useState(false);
    const [editId, setEditId] = useState(null);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

    const buildBtnAddAndBack = () => {
        const StyledIcon = withStyles({
            root: {
                color: "#ffffff",
                fontSize: "1.7vw"
            }
        })(AddIcon);

        return (
            <Tooltip title={!onForm ? "Adicionar novo campo" : "Retornar a listagem"}>
                <Fab color="primary"
                    aria-label="add"
                    onClick={() => {
                        setOnForm(!onForm); 
                        setEditId(null);
                    }}
                    className={"telephone__floating telephone__floating--icon " + (onForm ? 'telephone__floating--icon-rotate' : '')}>
                    <StyledIcon></StyledIcon>
                </Fab>
            </Tooltip>
        );
    };

    return (
        <Fragment>
            {buildBtnAddAndBack()}
            <div className={'table__container ' + (onForm ? 'table__container--slide' : '')}>
                <div>
                    {CustomFieldListView(
                        onForm,
                        (id) => {
                            setEditId(id);
                            setOnForm(true);
                        }
                    )}
                </div>
                <div>
                    {CustomFieldStoreView(
                        () => {
                            setOnForm(false);
                            setEditId(null);
                        },
                        (isLoading) => setShowLoadingSpinner(isLoading),
                        editId
                    )}
                </div>
            </div>

            <LoadingSpinner visible={showLoadingSpinner}/>
        </Fragment>
    );
}

export default CustomFieldManager;
