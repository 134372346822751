import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import InputUtils from "../../../utils/inputUtils";
import CanhotoTelephoneLocation from "../CanhotoTelephoneLocation";
import Image from "../../../../assets/images/no-location-phone.png";

const dialogLocation = (open, location, pendencies, onClose) => {

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            scroll={'paper'}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-location-title"
            aria-describedby="alert-dialog-location-description"
        >
            <DialogTitle id="alert-dialog-location-title" className={'text-center'}>
                {(location && 'Última localização do telefone')}
            </DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText id="alert-dialog-location-description" style={{maxHeight: 700, overflowY: "auto"}}>
                    {(pendencies.length > 0 ?
                            <div>
                                {location &&
                                    <h3>
                                        Última atualização: {InputUtils.formatFullDate(location.dhCriacao)}
                                    </h3>
                                }
                                <CanhotoTelephoneLocation
                                    latitude={location != null ? location.latitude : null}
                                    longitude={location != null ? location.longitude : null}
                                />

                                <hr/>
                                <h3 className='text-center'>Documentos fiscais</h3>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Série</th>
                                        <th scope="col">Número</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Data emissão</th>
                                        <th scope="col">Destinatário</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pendencies.map((pen, index) => (
                                        <tr key={index}>
                                            <td>{pen.series}</td>
                                            <td>{pen.number}</td>
                                            <td>{pen.documentType}</td>
                                            <td>{InputUtils.formatDate(pen.issueDate)}</td>
                                            <td>{pen.recipientName}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className={'text-center'}>
                                <img className={'text-center'} src={Image} alt="Este telefone não tem pendencia de entrega"
                                     style={{width: 250}}/>
                                <p className={'text-center'}>Este telefone não tem pendencia de entrega</p>
                            </div>
                    )}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default dialogLocation;