import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import userProfileUtils from "../utils/userProfileUtils";
import axios from 'axios';
import * as global from '../constants/global';

const CUSTOM_FIELD_TYPES_ENDPOINT = () => global.STUB_HOST + '/v1/custom-field-types';
const CUSTOM_FIELD_BASE_ENDPOINT = (companyId) => global.STUB_HOST + '/v1/companies/' + companyId + '/custom-fields';
const CUSTOM_FIELD_ENDPOINT = (companyId, customFieldId) => CUSTOM_FIELD_BASE_ENDPOINT(companyId) + '/' + customFieldId;

export default class CustomFieldApi {

    static getCustomFieldTypes() {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: CUSTOM_FIELD_TYPES_ENDPOINT(),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token},
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404)
                return error.response.data;
            throw error;
        });
    }

    static getCustomFields() {
        let user = userProfileUtils.getCurrentUser();
        let company = userProfileUtils.getCurrentCompany();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: CUSTOM_FIELD_BASE_ENDPOINT(company.id),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token},
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404)
                return error.response.data;
            throw error;
        });
    }

    static createCustomField(data) {
        let user = userProfileUtils.getCurrentUser();
        let company = userProfileUtils.getCurrentCompany();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_POST,
            url: CUSTOM_FIELD_BASE_ENDPOINT(company.id),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token},
            data: JSON.stringify(data)
        }).then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
    }

    static findCustomField(customFieldId) {
        let user = userProfileUtils.getCurrentUser();
        let company = userProfileUtils.getCurrentCompany();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: CUSTOM_FIELD_ENDPOINT(company.id, customFieldId),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token}
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 403 || status == 404)
                return error.response.data;
            throw error;
        });
    }

    static deleteCustomField(customFieldId) {
        let user = userProfileUtils.getCurrentUser();
        let company = userProfileUtils.getCurrentCompany();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_DELETE,
            url: CUSTOM_FIELD_ENDPOINT(company.id, customFieldId),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token}
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 403 || status == 404)
                return error.response.data;
            throw error;
        });
    }

    static updateCustomField(customFieldId, data) {
        let user = userProfileUtils.getCurrentUser();
        let company = userProfileUtils.getCurrentCompany();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_PUT,
            url: CUSTOM_FIELD_ENDPOINT(company.id, customFieldId),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token},
            data: JSON.stringify(data),
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 403 || status == 404)
                return error.response.data;
            throw error;
        });
    }

}