import { Avatar, Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Hidden, IconButton, InputAdornment, Link, Paper, TextField, Typography, Zoom, makeStyles, useTheme } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import SocialMidia from './SocialMidia';
import Vmodal from './modal/Vmodal';
import CloseIcon from '@material-ui/icons/Close';
import UsersApi from '../api/usersApi';
import ApiUtils from '../utils/apiUtils';
import ForgotPassword from './ForgotPassord';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    // backgroundColor: theme.palette.primary.main
  },
  image: {
    backgroundImage: 'url(/assets/images/background-canhoto-2.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 150,
    paddingRight: 150,
  },
  // avatar: {
  //   width: theme.spacing(7),
  //   height: theme.spacing(7),
  // },
  form: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  submit: {
    marginTop: 40,
    marginBottom: 20
  },
  buttonProgress: {
    position: 'absolute',   
  },
  scrollbar: {
    backgroundColor:"#ffffff",
    '&::-webkit-scrollbar': {
        width: '8px',// Largura do scrollbar
    },
    '&::-webkit-scrollbar-track': {
        background: '#f5f5f9', // Cor de fundo da trilha,     
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#e0e0e0', // Cor do scrollbar
        borderRadius: '4px', // Raio de borda do scrollbar
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: '#b3b3b3', // Cor do scrollbar ao passar o mouse
    },
},
modalHeader: {
    color: '#2a3566',
    backgroundColor: '#f5f5f5',
}
}));



function SigningForm({login}) {

  const classes = useStyles();
  const theme = useTheme()
  const imagePath = '/assets/images/logo-canhoto.png'; 
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)
  const [onForm, setOnForm] = useState(false)
  const [isFormLoading, setIsFormLoading] = useState(false)


  const handleOpenDialog = () => {
    setOnForm(true)
	}; 

  const handleCloseDialog = () => {
    setOnForm(false)
  }

  const clearForm = () => {
    setEmail("")
	};

  const handleChangeUser = (event) => {
    const value = event.target.value;
    setUserName(value)
  }

  const handleChangePassword = (event) => {
    const value = event.target.value;
    setPassword(value)
  }

  const handleChangeSendEmails = (event) => {
    const { value, name } = event.target
    setEmail(value)
  }

  const sendEmails = () => {
    let api = () => UsersApi.forgotPassword(email);
    ApiUtils.custom(handleLoadingSpinner, clearForm, api);    
  }

  const handleLoadingSpinner = (value) => {
    setShowLoadingSpinner(value)
	};
 

  return (
    <>
      <ForgotPassword
        isOpen={onForm}
        closeAction={handleCloseDialog}
        email={email}
        handleChange={handleChangeSendEmails}
        sendEmails={sendEmails}
      />  
      <Grid container item className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={12} md={6} className={classes.image} />       
          <Grid item xs={12} sm={12} md={6} component={Paper} square style={{ display: 'flex', height: '100vh', alignItems: 'center' }}>
            <div className={classes.paper}>
              <img className={classes.avatar} src={imagePath}  />
              <form className={classes.form} noValidate autoComplete='off' onSubmit={event => {
                        event.preventDefault();
                        login(username, password);
                      }}>
                <TextField
                  size='small'
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  label="Login"   
                  onChange={e => handleChangeUser(e, 'username')}
                  value={username}          
                />
                <TextField
                  size='small'
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  label="Senha"
                  type={passwordVisible ? "text" : "password"}
                  value={password}
                  onChange={e => handleChangePassword(e, 'password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
                        {passwordVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}             
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}               
                >
                Entrar              
                </Button>
              </form>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <Link component="button" variant="body2" onClick={handleOpenDialog} >
                    Esqueceu a senha?
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <SocialMidia />
                </Grid>
              </Grid>
            </div>
          </Grid>
       
      </Grid>
    </>
  );
}

export default SigningForm