import React from 'react';
import { Route, Redirect } from 'react-router';
import notificationUtils from '../../utils/notificationUtils';

const PrivateRoute = ({ isLogged, isTemp, key, path, render, exact }) => {
  return (
    <Route
      key={key}
      path={path}
      render={() => {        
          if (isTemp) {
            notificationUtils.warnTempAccount();
            return <Redirect to={{ pathname: '/login' }} />;
          } else if (isLogged) {
              return (render);
          } else {
            return <Redirect to={{ pathname: '/login' }} />;
          }
        }
      }
      exact={exact}
    />
  );
};

export default PrivateRoute;
