import React, { Fragment, useEffect, useState } from 'react';
import { ValidatorForm } from "react-material-ui-form-validator";
import Input from "../../../containers/form/Input";
import CheckIcon from '@material-ui/icons/Check';
import CustomFieldApi from '../../../api/CustomFieldApi';
import {
    Card, CardContent,
    Button,
    FormControl,
    Select, MenuItem,
    CircularProgress,
    Grid,
    FormControlLabel,
    RadioGroup,
    FormLabel,
    Radio
} from "@material-ui/core";
import OptionsTable from './optionsTable';
import NotificationUtils from '../../../utils/notificationUtils';

const buildNewCustomFieldForm = (onSubmit, setShowLoadingSpinner, editId) => {
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [minlength, setMinlength] = useState('');
    const [maxlength, setMaxlength] = useState('');
    const [mask, setMask] = useState('');
    const [regexValidation, setRegexValidation] = useState('');
    const [defaultValue, setDefaultValue] = useState('');
    const [acceptMultipleAnswer, setAcceptMultipleAnswer] = useState(false);
    const [required, setRequired] = useState(true);
    const [options, setOptions] = useState([]);

    const [customFielTypes, setCustomFielTypes] = useState([]);
    const [isFormLoading, setIsFormLoading] = useState(false);

    useEffect(() => loadCustomFielTypes(), []);
    useEffect(() => {
        if (!editId) {
            resetFields();

            return;
        }

        findCustomField(editId)
    }, [editId]);

    function loadCustomFielTypes() {
        CustomFieldApi.getCustomFieldTypes()
            .then(response => {
                setCustomFielTypes(response)
            })
    }

    function findCustomField(editId) {
        CustomFieldApi.findCustomField(editId)
            .then(response => {
                setName(response.name);
                setType(response.type);
                setPlaceholder(response.placeholder);
                setMinlength(response.minlength);
                setMaxlength(response.maxlength);
                setMask(response.mask);
                setRegexValidation(response.regexValidation);
                setDefaultValue(response.defaultValue);
                setAcceptMultipleAnswer(response.acceptMultipleAnswer);
                setRequired(response.required);
                setOptions(response.options);
            })
    }

    const handleSubmit = () => {
        setShowLoadingSpinner(true);
        setIsFormLoading(true);

        let data = {
            name,
            type,
            placeholder,
            minlength,
            maxlength,
            mask,
            regexValidation,
            defaultValue,
            acceptMultipleAnswer,
            required,
            options,
        };

        let promisseCustomField = null;
        if (editId) {
            promisseCustomField = CustomFieldApi.updateCustomField(editId, data)
        } else {
            promisseCustomField = CustomFieldApi.createCustomField(data);
        }

        promisseCustomField.then(() => {
            resetFields();

            setIsFormLoading(false);
            setShowLoadingSpinner(false);
            NotificationUtils.success('Campo personalizado atualizados com sucesso');

            onSubmit();
        }).catch((e) => {
            setIsFormLoading(false);
            setShowLoadingSpinner(false);
            NotificationUtils.error('Falha ao atualizar campo personalizado.');
        });
    };

    function resetFields() {
        setName('');
        setType('');
        setPlaceholder('');
        setMinlength('');
        setMaxlength('');
        setMask('');
        setRegexValidation('');
        setDefaultValue('');
        setAcceptMultipleAnswer(false);
        setRequired(true);
        setOptions([]);
    }

    const cardStyle = {
        width: '70%',
        margin: 'auto',
        paddingLeft: '15px',
        paddingBottom: '15px',
    };

    return (
        <Fragment>
            <Card style={cardStyle} elevation={3}>
                <h1 className='text-center'> Adicionar campo personalizado </h1>
                <CardContent>
                    <ValidatorForm
                        noValidate
                        autoComplete="new-password">
                        <Grid container md={12} spacing={4} direction="row">
                            <Grid md={6} item>
                                <Input
                                    validators={['required']}
                                    errorMessages={['Este campo é obrigatório']}
                                    label="Nome do campo"
                                    onChange={(e) => setName(e.target.value.slice(0,30))}
                                    value={name}
                                />
                            </Grid>
                            <Grid md={6} item>
                                <FormLabel component="legend">Tipo do campo</FormLabel>
                                <FormControl>
                                    <Select
                                        onChange={(e) => setType(e.target.value)}
                                        value={type}
                                    >
                                        {Object.entries(customFielTypes).map(([value, label]) => (
                                            <MenuItem value={value}>{label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid md={6} item>
                                <Input
                                    label="Dica de preenchimento do campo"
                                    onChange={(e) => setPlaceholder(e.target.value)}
                                    value={placeholder}
                                />
                            </Grid>
                            <Grid md={3} item>
                                <Input type="number"
                                    label="Tamanho mínimo"
                                    onChange={(e) => setMinlength(e.target.value)}
                                    value={minlength}
                                />
                            </Grid>
                            <Grid md={3} item>
                                <Input type="number"
                                    label="Tamanho máximo"
                                    onChange={(e) => setMaxlength(e.target.value)}
                                    value={maxlength}
                                />
                            </Grid>
                            <Grid md={3} item>
                                <Input
                                    label="Máscara para o campo"
                                    onChange={(e) => setMask(e.target.value)}
                                    value={mask}
                                />
                            </Grid>
                            <Grid md={3} item>
                                <Input
                                    label="Regex para validação"
                                    onChange={(e) => setRegexValidation(e.target.value)}
                                    value={regexValidation}
                                />
                            </Grid>
                            <Grid md={3} item>
                                <FormLabel component="legend">Obrigatório?</FormLabel>
                                <RadioGroup aria-label="required" name="required" value={required}
                                            onChange={(e) => {
                                                setRequired(e.target.value === 'true');
                                            }}>
                                    <FormControlLabel value={true} control={<Radio />} label="Sim" />
                                    <FormControlLabel value={false} control={<Radio />} label="Não" />
                                </RadioGroup>
                            </Grid>
                            <Grid md={3} item>
                                <Input
                                    label="Valor padrão"
                                    onChange={(e) => setDefaultValue(e.target.value)}
                                    value={defaultValue}
                                />
                            </Grid>
                            {['CHECKBOX', 'LIST'].includes(type) && (
                                <Grid md={3} item>
                                    <FormLabel component="legend">Aceita multipla seleção?</FormLabel>
                                    <RadioGroup aria-label="acceptMultipleAnswer" name="acceptMultipleAnswer" value={acceptMultipleAnswer}
                                        onChange={(e) => {
                                            setAcceptMultipleAnswer(e.target.value === 'true');
                                        }}>
                                        <FormControlLabel value={true} control={<Radio />} label="Sim" />
                                        <FormControlLabel value={false} control={<Radio />} label="Não" />
                                    </RadioGroup>
                                </Grid>
                            )}

                            {['CHECKBOX', 'LIST', 'RADIO'].includes(type) && (
                                <Grid md={12} item>
                                    <OptionsTable defaultRows={options} onRowsChange={(options) => setOptions(options)} />
                                </Grid>
                            )}

                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleSubmit}
                                fullWidth>
                                {isFormLoading ?
                                    <CircularProgress size={25} style={{ color: 'white', marginRight: '12px' }} /> :
                                    <CheckIcon style={{
                                        color: 'white',
                                        marginRight: '12px'
                                    }} />}
                                Salvar
                            </Button>
                        </Grid>
                    </ValidatorForm>
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default buildNewCustomFieldForm;