/**
 * Arrays utils
 */
class ArrayUtils {  
  static sort = (array, orderField) => {
    array.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      } else {
        return 1;
      }
    });
  
    array.forEach(item => {
      if (item.childrens) {
        ArrayUtils.sort(item.childrens, orderField);
      }
    });

    return array;
  }
}
export default ArrayUtils;  
