import { connect } from 'react-redux';
import LeftMenu from '../components/LeftMenu';
import { push } from 'connected-react-router';
import { userSignOut } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  items: state.leftmenu.items,
  logoImage: ownProps.logoImage,
  userImage: ownProps.userImage,
  cnpj: state.topbar.cnpj,
  companyName: state.topbar.companyName
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changePage: (page) => dispatch(push(page)),
  userSignOut: () => dispatch(userSignOut())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftMenu);
