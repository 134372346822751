import * as types from '../constants/actionTypes';
import * as httpStatus from '../constants/httpStatus';
import usersApi from '../api/usersApi';
import configurationsApi from '../api/configurationsApi';
import arrayUtils from '../utils/arrayUtils';
import notificationUtils from '../utils/notificationUtils';
import userProfileUtils from '../utils/userProfileUtils';
import TelephoneApi from '../api/telephoneApi';
import companiesApi from '../api/companyApi';
import '../api/axios';
import StubApi from "../api/stubApi";
import StubProfileUtils from '../utils/stubProfileUtils';
import CustomFieldApi from '../api/CustomFieldApi';

/**
 * Configurations
 */
export const loadConfigurations = () => {
  return function (dispatch) {
    return configurationsApi
      .getAll()
      .then(async configurations => checkConfIsRetrieved(dispatch, configurations))
      .then(async configurations => loadMenu(dispatch, configurations))
      .then(async configurations => loadCompany(dispatch, configurations))
      .then(async configAndCompany => loadLogo(configAndCompany))
      .then(async configAndCompany => loadTopBar(dispatch, configAndCompany))
      .then(async configAndCompany => loadMainModules(dispatch, configAndCompany))
      .catch(error => {
        notificationUtils.error(error);
        throw error;
    });
  };
};

const checkConfIsRetrieved = (dispatch, configurations) => {
  try {
    if (configurations === undefined || configurations.status === httpStatus.HTTP_STATUS_UNAUTHORIZED) {
      dispatch(userSignOut());
      notificationUtils.error("Falha ao recuperar as configurações.");
      return Promise.reject("Falha ao recuperar as configurações.");
    }
    return configurations;
  } catch (error) {
    notificationUtils.error("Falha ao recuperar as configurações. Detalhes: " + error);
    return Promise.reject("Falha ao recuperar as configurações. Detalhes: " + error);
  }
}

const loadMenu = (dispatch, configurations) => {
  try {
    let items = [];
    let menuConfig = configurations.find(config => config.type === 'menu');
    if (menuConfig.data !== undefined && menuConfig.data.items !== undefined) {
      items = arrayUtils.sort(menuConfig.data.items, 'order');
      dispatch(loadMenuSuccess(items));
    }
    return configurations;
  } catch (error) {
    notificationUtils.error("Falha ao carregar os menus. Detalhes: " + error);
    return Promise.reject("Falha ao carregar os menus. Detalhes: " + error);
  }
}

const loadCompany = async (dispatch, configurations) => {
  try {
    let currentCompany = userProfileUtils.getCurrentCompany();
    if (currentCompany != null)
      return { configurations, currentCompany };
    let companyConfig = configurations.find(config => config.type === 'company');
    if (companyConfig.data !== undefined && companyConfig.data.items !== undefined && companyConfig.data.items.length > 0) {
      currentCompany = companyConfig.data.items[0];
      currentCompany = {...currentCompany, id: null, logoUrl: null};
      return companiesApi.retrieve(currentCompany.cnpj)
              .then(response => {
                if (response.message.success) {
                  currentCompany.id = response.company.id;
                  userProfileUtils.setCurrentCompany(currentCompany);
                  return { configurations, currentCompany };
                }
                dispatch(userSignOut());
                notificationUtils.error(response.message.details);
                return Promise.reject(response.message.details);
              });
    } else {
      dispatch(userSignOut());
      notificationUtils.error("Nenhuma empresa vinculada!");
      return Promise.reject("Nenhuma empresa vinculada!");
    }
  } catch (error) {
    notificationUtils.error("Falha ao carregar a empresa. Detalhes: " + error);
    return Promise.reject("Falha ao carregar a empresa. Detalhes: " + error);
  }
}

const loadLogo = (configAndCompany) => {
  let configurations = configAndCompany.configurations;
  let currentCompany = configAndCompany.currentCompany;
  let flavourConfig = configurations.find(config => config.type === 'flavour');
  if (flavourConfig.data !== undefined && flavourConfig.data.logoUrl !== undefined) {
    currentCompany.logoUrl = flavourConfig.data.logoUrl;
  }
  return configAndCompany;
}

const loadTopBar = (dispatch, configAndCompany) => {
  let currentCompany = configAndCompany.currentCompany;
  let topbar = {
    logoUrl: currentCompany.logoUrl,
    cnpj: currentCompany.cnpj,
    companyName: currentCompany.companyName,
    supportVerih: userProfileUtils.setVerihInitialStatus(0),
    showVerihFlow: userProfileUtils.setShowVerihFlow(true),
    showVerihTop: userProfileUtils.setShowVerihTop(false),
    userName: userProfileUtils.getCurrentUser().username
  };
  dispatch(loadTopbarSuccess(topbar));
  return configAndCompany;
}

const loadMainModules = (dispatch, configAndCompany) => {
  dispatch(loadAllGrids());
  return configAndCompany;
}

export const loadAllGrids = () => {
  return function (dispatch) {
    dispatch(loadTelephone());
    dispatch(loadStub());
    dispatch(loadStubConfiguration());
  };
};

export const loadStubConfiguration = () => {
  return function (dispatch) {
    return StubApi
              .getStubProfile()
              .then(response => {
                let message = response.message;
                if (message.success) {
                  StubProfileUtils.setStubProfile(response.customFields);
                  dispatch(loadStubConfigurationGrid(response.customFields));
                } else {
                  dispatch(loadStubConfigurationGrid([]));
                }
              })
              .catch((error) => {
                if(error.response && error.response.status == 500){
                  notificationUtils.error("API de Canhotos indisponível!");
                }
              });
  };
};

export const loadCustomField = () => {
  return function (dispatch) {
    return CustomFieldApi
              .getCustomFields()
              .then(response => {
                let message = response.message;
                if (message.success) {
                  dispatch(loadCustomFieldGrid(response.customFields));
                } else {
                  dispatch(loadCustomFieldGrid([]));
                }
              })
              .catch((error) => {
                if(error.response && error.response.status == 500){
                  notificationUtils.error("API indisponível!");
                }
              });
  };
};

export const loadCustomFieldGrid = items => {
  return {
    type: types.CUSTOM_FIELD_LIST_IS_LOADED,
    items
  };
};

export const loadTelephone = () => {
  return function (dispatch) {
    return TelephoneApi
              .filterBy()
              .then(response => {
                let message = response.message;
                if (message.success) {
                  dispatch(loadTelephoneGrid(response.companiesPhones));
                } else {
                  dispatch(loadTelephoneGrid([]));
                }
              })
              .catch((error) => {
                if(error.response && error.response.status == 500){
                  notificationUtils.error("API de Canhotos indisponível!");
                }
              });
  };
};

export const loadStub = () => {
  return function (dispatch) {
    return StubApi
        .filterBy()
        .then(response => {
          let message = response.message;
          if (message.success) {
            dispatch(loadStubGrid(response.stubs));
          } else {
            dispatch(loadStubGrid([]));
          }
        })
        .catch((error) => {
          if(error.response && error.response.status == 500){
            notificationUtils.error("API de Canhotos indisponível!");
          }
        });
  };
};

export const loadStubConfigurationGrid = items => {
  return {
    type: types.STUB_CONFIGURATION_IS_LOADED,
    items
  };
};

export const loadTelephoneGrid = items => {
  return {
    type: types.TELEPHONE_LIST_IS_LOADED,
    items
  };
};

export const loadStubGrid = items => {
  return {
    type: types.STUB_LIST_IS_LOADED,
    items
  };
};

export const loadStubPhone = telephone => {
  return {
    type: types.STUB_LIST_TELEPHONE,
    telephone
  };
};

export const loadMenuSuccess = items => {
  return {
    type: types.CONFIGURATION_MENU_IS_LOADED,
    items
  };
};

export const loadTopbarSuccess = topbar => {
  return {
    type: types.TOPBAR_IS_LOADED,
    topbar
  };
};

export const checkLogin = (username, password) => {
  return function (dispatch) {
    usersApi.checkLogin(username, password).then(response => {
      const { message } = response;
      if(message.success){
        dispatch(userSignInDispatcher(response));
      }else{
        notificationUtils.error(message.details);
        dispatch(userSignOut());
      }
    });
  };
};

export const userSignInDispatcher = (user = null) => {
  return function (dispatch) {
    if(!user.isTemp) {
      dispatch(userSignIn(user));
      dispatch(loadConfigurations());
    } else {
      dispatch(userSignIn(user));
      notificationUtils.warnTempAccount();
    }
  };
};

export const userSignIn = (user = null) => {
  return {
    type: types.USER_SIGN_IN,
    user
  };
};

export const userSignOut = () => {
  return {
    type: types.USER_SIGN_OUT
  };
};

/**
 * Company
 */
export const changeCompanyDispatcher = (company) => {
  return function (dispatch) {
    let user = userProfileUtils.getCurrentUser();
    let topbar = {
      logoUrl: company.logoUrl,
      cnpj: company.cnpj,
      companyName: company.companyName,
      userName: user.username
    };
    dispatch(loadTopbarSuccess(topbar));
    dispatch(userSignIn(user));
    dispatch(loadAllGrids());
  };
};
