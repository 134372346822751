import { connect } from 'react-redux';
import AppsManage from '../../components/pages/AppsManage';

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppsManage);