import React, { Fragment, useState } from 'react';

import PendenciesView from "./pendencies/list";
import LoadingSpinner from '../../containers/LoadingSpinner';

const Pendencies = () => {
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

    return (
        <Fragment>
            <div className={'table__container '}>
                <div>
                    {PendenciesView(
                        (isLoading) => setShowLoadingSpinner(isLoading),
                    )}
                </div>
            </div>

            <LoadingSpinner visible={showLoadingSpinner}/>
        </Fragment>
    );
}

export default Pendencies;
