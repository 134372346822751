import { connect } from 'react-redux';
import ConfigManage from '../../components/pages/ConfigManage';
import {loadStubGrid, loadStubPhone, loadTelephoneGrid} from '../../actions';
import {push} from "connected-react-router";

const mapStateToProps = (state, ownProps) => ({
  items: state.telephone.items
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadTelephoneGrid: (items) => dispatch(loadTelephoneGrid(items)),
  changePage: (page) => dispatch(push(page)),
  loadStubGrid: (items) => dispatch(loadStubGrid(items)),
  loadStubPhone: (telephone) => dispatch(loadStubPhone(telephone))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigManage);
