/**
 * Date utils
 */
class DateUtils {
  /**
   * @param {Date} date
   * @returns string - string date to UTC format
   */
  static formatUTC = (date) => {
    return JSON.parse(JSON.stringify(date).replace(/(\{|,)\s*(.+?)\s*:/g, '$1 "$2":'));
  }
}
export default DateUtils;
