import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, Zoom, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    scrollbar: {
        backgroundColor:"#ffffff",
        '&::-webkit-scrollbar': {
            width: '8px',// Largura do scrollbar
        },
        '&::-webkit-scrollbar-track': {
            background: '#f5f5f9', // Cor de fundo da trilha,     
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#e0e0e0', // Cor do scrollbar
            borderRadius: '4px', // Raio de borda do scrollbar
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#b3b3b3', // Cor do scrollbar ao passar o mouse
        },
    },
    modalHeader: {
        color: '#2a3566',
        backgroundColor: '#f5f5f5',
    }

}));

function Vmodal({ title, isOpen, closeAction, children, onClick, componentButton}) {

    const classes = useStyles();

    return (
        <Dialog       
            TransitionComponent={Zoom}
            fullWidth
            maxWidth="md"
            onClose={closeAction} aria-labelledby="customized-dialog-title" open={isOpen}>
            <DialogTitle onClose={closeAction} style={{ borderTop: '5px solid #ff3c00' }} className={classes.modalHeader}>
                <Grid container item >
                    <Grid item >
                        <Typography variant='h4' >{title}</Typography>
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item >
                        <IconButton edge="end" color="inherit" onClick={closeAction} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers  className={classes.scrollbar}>
                {children}              
            </DialogContent>
            <DialogActions className={classes.scrollbar}>
                {componentButton}               
            </DialogActions>
        </Dialog>
    )
}

export default Vmodal