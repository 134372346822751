export default {
    body: {
        noMatch: "Nenhum registro encontrado",
        toolTip: "Ordernar",
        columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
    },
    toolbar: {
        search: "Pesquisar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Selecionar colunas",
        filterTable: "Filtrar tabela",
    },
    filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Resetar",
    },
    viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Esconder Colunas",
    },
    selectedRows: {
        text: "Linha(s) selecionadas",
        delete: "Deletar",
        deleteAria: "Deletar linha selecionada",
    },
};