import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import PendenciesApi from "../../../api/pendenciesApi";

const dialogDelete = (open, ids = [], onClose, onStart, onFinish) => {

    function deleteIds() {
        onStart()
        const deletePromises = ids.map((id) => PendenciesApi.deletePendency(id));
        Promise.all(deletePromises).then(() => onFinish());
    }

    return (
        <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Atenção
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"Tem certeza que deseja remover " + (ids.length === 1 ? "esta pendência?" : "estas pendências?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Voltar
                    </Button>
                    <Button onClick={deleteIds} color="primary" autoFocus>
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

export default dialogDelete;