import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge';
import userProfileUtils from "../utils/userProfileUtils";
import stubProfileUtils from '../utils/stubProfileUtils';

const user = (state = userProfileUtils.getStateFromLocalStorage('user'), action) => {
  switch (action.type) {
    case types.USER_SIGN_IN: {
      if (action.user !== undefined) {
        let user = {
          userId: action.user.userId,
          username: action.user.username,
          isFirstAccess: action.user.isFirstAccess,
          isTemp: action.user.isTemp,
          accessToken: action.user.accessToken,
          tokenType: action.user.tokenType,
          trialAccessPlanInfo: action.user.trialAccessPlanInfo,
          refreshToken: action.user.refreshToken,
          productCodes: action.user.productCodes
        };
        userProfileUtils.setCurrentUser(user);
        userProfileUtils.changeCookiesLogged({ isLogged: true })
        return merge(state.user, user);
      }
      return state;
    }
    case types.USER_SIGN_OUT: {
      userProfileUtils.clearAll();
      stubProfileUtils.clearAll();
      userProfileUtils.setVerihInitialStatus(0);
      userProfileUtils.setShowVerihFlow(true);
      userProfileUtils.setShowVerihTop(false) ;
      return merge(state.user, initialState.user);
    }
    default:
      return state;
  }
};
export default user;
  