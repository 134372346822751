import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import userProfileUtils from "../utils/userProfileUtils";
import axios from 'axios';


class ConfigurationCompanyApi {
 
    static filterConfigCompany() {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;
        let company = userProfileUtils.getCurrentCompany();
        let companyId = company.id;
        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: apiEndpoints.CONFIG_COMPANY_ENDPOINT(companyId),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token },
        }).then(response => {
            //return response;
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static updateConfigurationCompany(config){
        const dados = config;   
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;
        let company = userProfileUtils.getCurrentCompany();
        let companyId = company.id;
        return  axios({
            method: httpMethods.REQUEST_METHOD_PUT,
            url: apiEndpoints.CONFIG_COMPANY_ENDPOINT(companyId),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token },
            data: JSON.stringify(dados)
            }).then(response => {
                return response.data;
            }).catch(error => {
                let status = error.response.status;
                if (status == 400 || status == 404 || status == 500)
                    return error.response.data;
                throw error;
        });
    }
}

export default ConfigurationCompanyApi;  