import moment from "moment";

class InputUtils {

    static formatPhone(phone) {
        if (phone == null || phone.length == 0) return "";

        let x = phone.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        return phone;
    }

    static formatDocument(doc) {
        if (doc == null || doc.length == 0) {
            return "";
        } else if (this.formatRemoveMask(doc).length <= 11) {
            let x = doc.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
            doc = !x[2] ? x[1] : x[1] + '.' + x[2] + (x[3] ? '.' + x[3] : '') + (x[4] ? '-' + x[4] : '');
        } else {
            let x = doc.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
            doc = !x[2] ? x[1] : x[1] + '.' + x[2] + (x[3] ? '.' + x[3] : '') + (x[4] ? '/' + x[4] : '') + (x[5] ? '-' + x[5] : '');
        }
        return doc;
    }

    static formatCnpj(doc) {
        if (doc == null || doc.length == 0) {
            return "";
        } 

        let x = doc.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        doc = !x[2] ? x[1] : x[1] + '.' + x[2] + (x[3] ? '.' + x[3] : '') + (x[4] ? '/' + x[4] : '') + (x[5] ? '-' + x[5] : '');
        return doc;
    }

    static formatDouble(number) {
        return number.toFixed(2);
    }

    static formatRemoveMask(value) {
        if(value == null || value == undefined ) return;
        return value.replace(/[^0-9]/g, "");
    }

    static formatDate(value) {
        if(!value) return;
        const date = new moment(value);
        return date.format("DD/MM/YYYY");
    }

    static formatFullDate(value) {
        if(!value) return;
        const date = new moment(value);
        return date.format("DD/MM/YYYY HH:mm");
    }

    static formatFullDateWithGMT(value){
        if(!value) return;
        let date = value.substring(0, 10).split('-').reverse().join('/');
        let timeOffset = value.substring(10, value.length);
        let fullDate = date + timeOffset;
        return fullDate;
    }

    static emptyToNull(value) {
        if (value == null || value == undefined || value === '')
            return null;
        return value;
    }


    static formatStubType(value) {
        switch(value) {
            case "NFE":
                return "NF-e";
            case "CTE":
                return "CT-e";
            case "NFCE":
                return "NFC-e";
            case "CFESAT":
                return "CF-e SAT";
            case "NFSE":
                return "NFS-e";
            case "OUTROS":
                return "Outro";
        }
    }
}

export default InputUtils;