import Cookies from 'js-cookie'
import * as global from '../constants/global'

class CookiesUtils{

    static setItem(key, obj){
        Cookies.set(key, obj,  {expires: 1, domain: global.COOKIE_DOMAIN })
    }

    static getItem(key) {
        return Cookies.get(key);      
    }

    static removeItem(key) {
        Cookies.remove(key,  {expires: 1, domain: global.COOKIE_DOMAIN })      
    }

    // static clearAll() {
    //     Cookies.clearAll();        
    // }
}

export default CookiesUtils;

