import { connect } from 'react-redux'
import App from '../components/App'

const mapStateToProps = (state, ownProps) => ({
  innerContent: ownProps.innerContent,
  title: ownProps.title,
  breadCrumbs: ownProps.breadCrumbs
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
