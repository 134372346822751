import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge';

const telephone = (state = initialState.telephone, action) => {
  switch (action.type) {
    case types.TELEPHONE_LIST_IS_LOADED: {
      let telephone = {
        items: action.items
      };
      return merge(state.telephone, telephone);
    }     
    default:
      return state;
  }
};

export default telephone;