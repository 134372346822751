import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import PendenciesApi from "../../../api/pendenciesApi";
import TelephoneApi from "../../../api/telephoneApi";

const dialogAssociatePhone = (open, pendency, onClose, onStart, onAssociate) => {

    const [phones, setPhones] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredPhones, setFilteredPhones] = useState([]);

    useEffect(() => {
        if (open) {
            loadPhones();
        }
    }, [open, pendency]);

    function loadPhones() {
        TelephoneApi.filterBy('ALL', 2000)
            .then((response) => {
                let phones = response.companiesPhones;
                phones = phones == null ? [] : phones;
                setPhones(phones);
                setFilteredPhones(phones);
            })
    }

    function bindWithPhone(phone) {
        onStart();
        PendenciesApi
            .bindWithPhone(phone.phoneId, [pendency.id])
            .then(() => {
                setSearch('');
                onAssociate();
            });
    }

    function unbindWithPhone(phone) {
        onStart();
        PendenciesApi
            .unbindWithPhone(phone.phoneId, pendency.id)
            .then(() => {
                setSearch('');
                onAssociate();
            });
    }

    const handleSearchChange = (event) => {
        const searchValue = event.target.value;
        setSearch(searchValue);
        filterItems(searchValue);
    };

    const filterItems = (searchValue) => {
        if (!searchValue) {
            setFilteredPhones(phones);
        } else {
            const filtered = phones.filter(item =>
                item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.phoneNumber.includes(searchValue)
            );
            setFilteredPhones(filtered);
        }
    };

    return (
        <Dialog
                open={open}
                onClose={() => {
                    setSearch('');
                    onClose();
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
            >
            <DialogTitle id="alert-dialog-title">
                Número: {pendency.number} | Série: {pendency.serie} | Destinatário: {pendency.nameReceiver}
            </DialogTitle>
            <DialogContent>
                    <div>
                        <div>

                            <TextField
                                name="number"
                                label="Pesquisar"
                                value={search}
                                onChange={handleSearchChange}
                                variant="outlined"
                                placeholder="Pesquisar ..."
                            />
                            <hr/>
                        </div>
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Número</th>
                                <th scope="col">Ações</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredPhones.map((phone, index) => (
                                <tr key={index}>
                                    <td>{phone.name}</td>
                                    <td>{phone.phoneNumber}</td>
                                    <td>
                                        {pendency.phoneId === phone.phoneId &&
                                            <button className="btn btn-default"
                                                    onClick={() => unbindWithPhone(phone)}>
                                                Desassociar
                                            </button>
                                        }
                                        {pendency.phoneId !== phone.phoneId &&
                                            <button className="btn btn-default"
                                                    onClick={() => bindWithPhone(phone)}>
                                                Associar
                                            </button>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setSearch('');
                    onClose();
                }}>
                    Voltar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default dialogAssociatePhone;