import { toast } from "react-toastify";
import React from "react";
import toastConfig from "../config/toast";
import '../assets/notifications.css';
import * as global from '../constants/global';
import { Button, Grid, Typography } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import CancelIcon from '@material-ui/icons/Cancel';


const createNotificationSkeleton = (message, title, type) => {
  let notificationJsx = (
    <section className="notification">
      <div className="icon">
        <i className={toastConfig.toast[type]} />
      </div>
      <div className="content">
        <span className="title">{title}</span>
        <br />
        <span className="message">{message}</span>
      </div>
    </section>
  );
  return notificationJsx;
};

const createNotificationSkeletonWithLink = ( title, type) => {
  let notificationJsx = (
    <section className="notification">
      <div className="icon">
        <i className={toastConfig.toast[type]} />
      </div>
      <div className="content">
        <span className="title">{title}</span>
        <br />
        <span className="message">Senha temporaria. Acesse o </span>
        <a href={global.MINHA_CONTA_URL_SUPPORT}>Minha Conta</a>
        <span className="message"> para alterar a senha e poder acessar este produto.</span>
      </div>
    </section>
  );
  return notificationJsx;
};

const CreateNotificationSkeletonWithLink = ({ closeToast, title, type}) => (
  <section style={{ width: '300px', padding:2, background:"#f4f4f4"}}>
       <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"          
        >
          <Grid container item lg={12} md={12} xs={12} sm={12}  spacing={1}>
            <Grid container item lg={12} md={12} xs={12} sm={12}  direction="row"  justifyContent="flex-end"  alignItems="flex-start">
              <CancelIcon style={{color:"red", marginRight: -10}} onClick={closeToast} />
            </Grid>
            <Grid container item lg={4} md={4} xs={4} sm={4}  direction="row"  justifyContent="flex-end"  alignItems="center">
              <i className={toastConfig.toast[type]} style={{color:"#083053", fontSize:35}} />
            </Grid>
            <Grid container item lg={8} md={8} xs={8} sm={8}>
              <Typography variant="h3" style={{fontWeight:'bold', marginLeft:5, color: "#083053"}}>{title}</Typography>
              {/* <span style={{color:"#000000", fontSize:"20px"}}>{title}</span> */}
            </Grid>
            <Grid container item lg={12} md={12} xs={12} sm={12} justifyContent="center"  alignItems="center" style={{marginBottom:5, color: "#083053"}}>
              <Typography variant="h5" justifyContent="center"  alignItems="center" align="center" >Esta senha é temporária. Acesse sua conta no Minha Conta para realizar a troca e garantir a segurança de suas informações.</Typography>
            </Grid>
            <Grid container item lg={12} md={12} xs={12} sm={12} justifyContent="center"  alignItems="center" style={{marginBottom:5}}>
            <Button variant="contained" color="primary" href={global.MINHA_CONTA_URL_SUPPORT} target="_blank" size="small"  startIcon={<PersonIcon style={{color: "#ffffff"}} />}>
            {/* <Button variant="contained" color="secondary" href={global.MINHA_CONTA_URL_PASSWORD} target="_blank" size="small"  startIcon={<PersonIcon style={{color: "#000000"}} />}> */}
              <Typography style={{fontSize:13}}>Minha Conta</Typography>
            </Button>
            {/* <Button variant="contained" color="secondary" target="_blank" size="small"  startIcon={<PersonIcon style={{color: "#000000"}} />} onClick={closeToast}>
              Fechar
            </Button> */}
              {/* <a href={global.MINHA_CONTA_URL_SUPPORT} target="_blank" style={{marginTop:20, color:"#000000", fontSize:"20px", background: "#f4a72f", padding:10, borderRadius:8}} >Trocar Senha</a>    */}
            </Grid>
          </Grid>
          {/* <Grid container item lg={12} md={12} xs={12} sm={12}>
          </Grid> */}
        </Grid>
    </section>
)

/**
 * Notification utils
 */
class NotificationUtils {
  static success = (message, title = "Sucesso") => {
    toast.success(createNotificationSkeleton(message, title, "success"));
  };

  static error = (message, title = "Erro") => {
    toast.error(createNotificationSkeleton(message, title, "error"));
  };

  static warn = (message, title = "Aviso") => {
    toast.warn(createNotificationSkeleton(message, title, "warning"));
  };
  
  // static warnTempAccount = (title = "Aviso") => {
  //   toast.warn(createNotificationSkeletonWithLink(title, "warning"));
  // };
  static warnTempAccount = (title = "Aviso") => {
    toast(<CreateNotificationSkeletonWithLink title={title} type="warning" />, {
      position: "top-center",
      hideProgressBar: false,
      autoClose: false,
      closeButton: true,    
      theme: "light",     
      draggable: true,
      closeOnClick: false,
    });
  };

  static info = (message, title = "Informação") => {
    toast.info(createNotificationSkeleton(message, title, "info"));
  };
}

export default NotificationUtils;
