import { connect } from 'react-redux';
import StubManage from '../../components/pages/StubManage';
import {loadStubGrid, loadStubPhone} from '../../actions';

const mapStateToProps = (state, ownProps) => {
  return {
    items: state.stub.items,
    telephone: state.stub.telephone
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadStubGrid: (items) => dispatch(loadStubGrid(items)),
  loadStubPhone: (telephone) => dispatch(loadStubPhone(telephone))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StubManage);
