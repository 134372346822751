import * as apiEndpoints from '../constants/apiEndpoints';
import * as global from '../constants/global';
import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import userProfileUtils from "../utils/userProfileUtils";
import axios from 'axios';
import InputUtils from "../utils/inputUtils";

class TelephoneApi {

    static convertPhoneToDTO(telephone) {
        const {phone, nome, document, email, pin, authorizedCnpjs, allowedAllCnpj} = telephone;
        let company = userProfileUtils.getCurrentCompany();
        return {
            "companyCpfCnpj": company.cnpj,
            "phoneNumber": InputUtils.formatRemoveMask(phone),
            "name": InputUtils.emptyToNull(nome),
            "document": InputUtils.formatRemoveMask(document),
            "email": InputUtils.emptyToNull(email),
            "pin": InputUtils.emptyToNull(pin),
            "authorizedDocuments": authorizedCnpjs.map(item => ({document: InputUtils.formatRemoveMask(item)})),
            "allowedAllCnpj": allowedAllCnpj
        };
    }

    static generateAxiosHeaders() {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;

        return {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token};
    }

    static filterBy(status = 'ALL', endLimit = global.MAX_RECORDS_PER_PAGE) {
        let company = userProfileUtils.getCurrentCompany();
        let companyId = company.id;
        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: apiEndpoints.PHONE_LIST_ENDPOINT(companyId),
            headers: this.generateAxiosHeaders(),
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static retrieve(companyPhoneId) {
        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: apiEndpoints.PHONE_RETRIEVE_ENDPOINT(companyPhoneId),
            headers: this.generateAxiosHeaders(),
            data: {}
        }).then(response => {
            return response.data.companyPhone;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static deleteTelephone(companyPhoneId) {
        return axios({
            method: httpMethods.REQUEST_METHOD_DELETE,
            url: apiEndpoints.PHONE_DELETE_ENDPOINT + '/' + companyPhoneId,
            headers: this.generateAxiosHeaders(),
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static updateTelephone(telephone, phoneId) {
        let data = this.convertPhoneToDTO(telephone);

        return axios({
            method: httpMethods.REQUEST_METHOD_PUT,
            url: apiEndpoints.PHONE_UPDAET_ENDPOINT(phoneId),
            headers: this.generateAxiosHeaders(),
            data: JSON.stringify(data)
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static saveTelephone(telephone) {
        let data = this.convertPhoneToDTO(telephone);

        return axios({
            method: httpMethods.REQUEST_METHOD_POST,
            url: apiEndpoints.PHONE_NEW_ENDPOINT,
            headers: this.generateAxiosHeaders(),
            data: JSON.stringify(data)
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static findLastLocation(phoneId) {
        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: global.STUB_HOST + `/v1/phones/${phoneId}/locations`,
            headers: this.generateAxiosHeaders(),
        });
    }

    static getPendenciesFromPhone(phoneId) {
        let company = userProfileUtils.getCurrentCompany();

        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: global.STUB_HOST + `/v1/stubs/phones/${phoneId}/pendencies`,
            headers: this.generateAxiosHeaders(),
            params: {
                companyId: company.id
            }
        });
    }
}

export default TelephoneApi;  