import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, ListItemText,
    MenuItem, OutlinedInput,
    Select
} from "@material-ui/core";
import PendenciesApi from "../../../api/pendenciesApi";
import TelephoneApi from "../../../api/telephoneApi";
import InputUtils from "../../../utils/inputUtils";
import Chip from "@material-ui/core/Chip";

const dialogBatchAssociate = (open, ids, onClose, onStart, onFinish) => {

    const [phoneId, setPhoneId] = useState(null);
    const [phones, setPhones] = useState([]);

    useEffect(() => {
        if (open) {
            loadPhones();
        }
    }, [open, ids]);

    function loadPhones(showLoader) {
        TelephoneApi.filterBy('ALL', 2000)
            .then((response) => {
                let phones = response.companiesPhones;
                setPhones(phones == null ? [] : phones);
            })
    }

    function bindIds() {
        onStart()
        PendenciesApi
            .bindWithPhone(phoneId, ids)
            .then(() => {
                setPhoneId(null);
                onFinish();
            });
    }

    return (
        <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle id="alert-dialog-title">
                    Associar pendênci{ids.length > 1 ? 'as' : 'a'} ao telefone.
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <Select
                            labelId="phoneId-label"
                            id="phoneId"
                            name="phoneId"
                            required={true}
                            value={phoneId}
                            renderValue={(selected) => {
                                const phone = phones.find(phone => phone.phoneId === selected);
                                return (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {phone && (
                                            <Chip
                                                key={selected}
                                                label={`${InputUtils.formatPhone(phone.phoneNumber)} (${phone.name})`}
                                            />
                                        )}
                                    </Box>
                                );
                            }}
                            input={<OutlinedInput label="Telefones" />}
                            onChange={(e) => setPhoneId(e.target.value)}
                        >
                            {phones.map((phone, index) => (
                                <MenuItem key={phone.phoneId} value={phone.phoneId}>
                                    <ListItemText primary={InputUtils.formatPhone(phone.phoneNumber) + '-' + phone.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Voltar
                    </Button>
                    <Button onClick={bindIds} color="primary" autoFocus>
                        Associar
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

export default dialogBatchAssociate;