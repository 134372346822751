import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import userProfileUtils from "../utils/userProfileUtils";
import axios from 'axios';

class ActuatorApi {
 
    static filterVersion() {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;        
        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: apiEndpoints.ACTUATOR_ENDPOINT,
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token },
        }).then(response => {
            return response.data.build;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

}

export default ActuatorApi; 