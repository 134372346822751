import { connect } from 'react-redux'
import PublicRoute from '../../components/routes/PublicRoute'
import userProfileUtils from '../../utils/userProfileUtils'
import { AUTH_PRODUCT_CODE } from '../../constants/global';

const mapStateToProps = (state, ownProps) => ({
  isLogged: userProfileUtils.isUserAuthorized(AUTH_PRODUCT_CODE),
  isTemp: userProfileUtils.isUserTemp(),
  isFirstAccess: state.user.isFirstAccess,
  key: ownProps.key,
  path: ownProps.path,
  render: ownProps.render,
  exact: ownProps.exact
});

const mapDispatchToProps = (_dispatch, _ownProps) => ({
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicRoute);
