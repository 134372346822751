import theme from "../../../Theme";
import ClearIcon from "@material-ui/icons/Clear";
import {
    Button, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select,
    TextField
} from "@material-ui/core";
import InputUtils from "../../../utils/inputUtils";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment/moment";
import MomentUtils from "@date-io/moment";
import {ThemeProvider} from "@material-ui/core/styles";
import React, {useState} from "react";

const filterPendencies = (onFilter, onClearFilter) => {

    const [showFilter, setShowFilter] = useState(false);
    const [onDetails, setOnDetails] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [cnpjReceiver, setCnpjReceiver] = useState('');
    const [nameReceiver, setNameReceiver] = useState('');
    const [number, setNumber] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [documentTypes, setDocumentTypes] = useState(new Set());
    const [bound, setBound] = useState('ALL');

    const localTheme = {
        ...theme,
        overrides: {
            ...theme.overrides,

            MuiOutlinedInput: {
                input: {
                    fontSize: '1.3rem',
                    fontWeight: 300,
                    padding: '11.5px 17px'
                }
            },
            MuiButton: {
                label: {
                    fontSize: "14px"
                }
            },
            MuiIconButton: {
                root: {
                    padding: 0
                }
            }
        }
    };

    function applyFilter() {
        setIsFiltered(true);
        setShowFilter(false);

        onFilter({
            recipientCnpjCpf: cnpjReceiver,
            documentType: Array.from(documentTypes),
            recipientName: nameReceiver,
            number: number,
            startIssueDate: startDate,
            endIssueDate: endDate,
            bound: bound,
        });
    }

    function cleanFilters() {
        setShowFilter(false);
        setOnDetails(false);
        setIsFiltered(false);
        setCnpjReceiver('');
        setNameReceiver('');
        setNumber(null);
        setStartDate(null);
        setEndDate(null);
        setDocumentTypes(new Set());
        setBound('ALL');

        onClearFilter();
    }

    const handleCheckboxChange = (ev) => {
        const newSet = new Set(documentTypes);
        if (ev.target.checked) {
            newSet.add(ev.target.name);
        } else {
            newSet.delete(ev.target.name);
        }
        setDocumentTypes(newSet);
    };

    return (
        <ThemeProvider theme={localTheme}>
            <div style={{transform: showFilter ? 'translateY(0)' : 'translateY(300px)'}}
                className={"stub-filter " + (onDetails ? " stub-filter__down " : (showFilter ? "" : " stub-filter__disabled "))}>
                <div className="stub-filter__title" style={{borderRadius: '10px 10px 0px 0px'}}>
                    <div style={{width: '100%'}}  onClick={() => setShowFilter(!showFilter)}>
                        <h1>Adicionar Filtros</h1>
                    </div>
                    <div className={"stub-filter__clean " + (isFiltered ? "" : "stub-filter__clean--disabled")}
                         onClick={() => cleanFilters()} style={{borderRadius: '0px 10px 0px 0px'}}>
                        <ClearIcon className="stub-filter__clean-icon" />
                        <h1>Limpar filtros</h1>
                    </div>
                </div>
                <div className="stub-filter__container">
                    <div className="stub-filter__data">
                        <table style={{width: '100%'}}>
                            <tr>
                                <th>Tipo de documento:</th>
                                <td>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={documentTypes.has('NFE')}
                                                               onChange={handleCheckboxChange}
                                                               name="NFE"/>}
                                            label="NF-e"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={documentTypes.has('CTE')}
                                                               onChange={handleCheckboxChange}
                                                               name="CTE"/>}
                                            label="CT-e"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={documentTypes.has('NFCE')}
                                                               onChange={handleCheckboxChange}
                                                               name="NFCE"/>}
                                            label="NFC-e"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={documentTypes.has('CFESAT')}
                                                               onChange={handleCheckboxChange}
                                                               name="CFESAT"/>}
                                            label="CF-e SAT"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={documentTypes.has('NFSE')}
                                                               onChange={handleCheckboxChange}
                                                               name="NFSE"/>}
                                            label="NFS-e"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={documentTypes.has('OUTROS')}
                                                               onChange={handleCheckboxChange}
                                                               name="OUTROS"/>}
                                            label="Outros"
                                        />
                                    </FormGroup>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="stub-filter__data">
                        <table className="stub-filter__left">
                            <tr>
                                <th>Pendências associadas: </th>
                                <td>
                                    <FormControl variant="outlined" style={{
                                        margin: 2, marginLeft: 10, maxWidth: 390
                                    }}>
                                        <Select
                                            value={bound}
                                            name="bound"
                                            onChange={val => {
                                                setBound(val.target.value)
                                            }}
                                        >
                                            <MenuItem value="ALL">Todas</MenuItem>
                                            <MenuItem value={'BOND'}>Com Entregador</MenuItem>
                                            <MenuItem value={'WITHOUT_BOND'}>Sem Entregador</MenuItem>
                                        </Select>
                                    </FormControl>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="stub-filter__data">
                        <table className="stub-filter__left">
                            <tr>
                                <th style={{paddingRight: '54px'}}>CNPJ destinatário:</th>
                                <td>
                                    <TextField fullWidth name="cnpj"
                                               value={cnpjReceiver} onChange={val => {
                                        setCnpjReceiver(InputUtils.formatDocument(val.target.value));
                                    }} variant="outlined"/>
                                </td>
                            </tr>
                            <tr>
                                <th style={{paddingRight: '54px'}}>Nome destinatário:</th>
                                <td>
                                    <TextField fullWidth name="name"
                                               value={nameReceiver} onChange={val => {
                                        setNameReceiver(val.target.value);
                                    }} variant="outlined"/>
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <th>Data de emissão:</th>
                                <td>
                                    <div className="stub-filter__date">
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                disableFuture
                                                inputVariant="outlined"
                                                value={startDate}
                                                onChange={date => setStartDate(date)}
                                                format="DD/MM/YYYY"
                                                maxDate={new Date()}
                                            />
                                            <p>até</p>
                                            <KeyboardDatePicker
                                                disableFuture
                                                inputVariant="outlined"
                                                value={endDate}
                                                onChange={date => setEndDate(date)}
                                                format="DD/MM/YYYY"
                                                maxDate={new Date()}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Número:</th>
                                <td>
                                    <TextField name="number" value={number}
                                               onChange={val => setNumber(val.target.value)} variant="outlined"/>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="stub-filter__apply">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{margin: "13px 18vw 0"}}
                            fullWidth
                            onClick={() => applyFilter()}>
                            Aplicar
                        </Button>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default filterPendencies;