import React, { useEffect, useState } from 'react'
import { Avatar, Divider, makeStyles, Tooltip, Typography, Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
import Draggable from 'react-draggable';

import userProfileUtils from '../utils/userProfileUtils';
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: 'calc(100% / 2)',
    right: 20,
    zIndex: 9999,
    backgroundColor: '#ffffff',
    width: 100,
    borderRadius: 15,
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
  },
  avatar: {
    display: 'flex',
    width: theme.spacing(7),
    height: theme.spacing(7),
    background: '#dedede',
    outline: 'solid rgba(150, 150, 150, .25)',
    outlineWidth: '1px',
    outlineOffset: '0',
    margin: theme.spacing(2, 1),
    transition: 'all .25s ease-in-out',
  },
  avatarActive: {
    background: 'radial-gradient(ellipse at center, #01FF88, #01955A)',
    outlineWidth: '10px',
  },
  popContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    padding: theme.spacing(2, 2),
  },
  tooltip: {
    //border:'1px solid #ffffff',  
    background: 'radial-gradient(ellipse at center, #3e4c8b, #364379, #2e3866)',
    color: '#ffffff',
    padding: 15,
    borderRadius: 10,
    boxShadow: '0px 23px 18px -7px rgba(25, 25, 25, .5)',
    minWidth: 256,
  },
  containerHandle: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    color: '#cacaca',
    cursor: 'grab',
    '&:hover': {
      backgroundColor: '#f2f2f2',
      borderRadius: '0px 0px 15px 15px',
      color: '#2e3866'
    },
  },
  avatarContainer: {
    cursor: 'pointer',
    transition: 'all .25s',
    '&:hover #verih-avatar': {
      background: theme.palette.type === "dark" ? '#f29320' : 'radial-gradient(ellipse at center, #01FF88, #01955A)',
      outlineWidth: '10px',
    }
  },
  closeIcon: {
    cursor: 'pointer',
    color: '#f3941e',
    padding: 1,
    backgroundColor: '#f3dcc2',
    borderRadius: 100,

  }
}));

function SupportPop(props) {

  const classes = useStyles();

  const { url, setUrl, showVerihFlow, setShowVerihFlow, showVerihTop, setShowVerihTop } = props;
  const { avatar, setAvatar } = props;
  const [open, setOpen] = React.useState(false);
  const [typedText, setTypedText] = useState('');
  const [text, setText] = useState('Acesse nossa central de suporte.');
  const [activeClass, setActiveClass] = useState(false);

  let userViewTutorial = userProfileUtils.getVerihInitialStatus();

  //let showVerih = userProfileUtils.getShowVerih();

  //console.log(userProfileUtils.getCurrentCompany());
  useEffect(() => {
    if (userViewTutorial == 0) {
      const openTimeout = setTimeout(() => {
        setOpen(true);
        setActiveClass(true);
      }, 25);

      const timeout = setTimeout(() => {
        setOpen(false);
        setActiveClass(false);
        userProfileUtils.setVerihInitialStatus(1);
      }, 4500);
      return () => clearTimeout(openTimeout, timeout);
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (typedText.length < text.length) {
        setTypedText(text.slice(0, typedText.length + 1));
      } else {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [text, typedText]);

  const closeSupport = () => {
    userProfileUtils.setShowVerihFlow(false)
    userProfileUtils.setShowVerihTop(true)
    if (userProfileUtils.getShowVerihFlow() == false) {
      setShowVerihFlow(false)
    }
    if (userProfileUtils.getShowVerihTop()) {
      setShowVerihTop(true)
    }
  }


  const openTooltip = () => {
    setOpen(true);
  };

  const closeTooltip = () => {
    setOpen(false);
  }

  const handleClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    showVerihFlow &&
    <Draggable bounds='body' handle='#handle'
      style={{

      }}
    >
      <Grid container className={classes.container}  >
        <Grid container item
          justifyContent="flex-end"
          alignItems="center"
        >
          <Tooltip title="Esconder Verih suporte" placement="top">
            <CancelIcon className={classes.closeIcon} onClick={closeSupport} />
          </Tooltip>
        </Grid>
        <Tooltip id='dialog-balloon-verih' arrow PopperProps={{
          disablePortal: true,
        }}
          minWidth={250}
          open={open} disableFocusListener
          disableHoverListener
          disableTouchListener interactive placement='left' classes={{ tooltip: classes.tooltip }}
          title={
            <Grid container item >
              <Typography variant='h4' style={{ fontWeight: '500' }} gutterBottom>Precisa de ajuda?</Typography>
              <Typography variant='body2' style={{ fontWeight: '500' }}>{typedText}</Typography>
            </Grid>
          }>
          <Grid container item alignItems='flex-start' justifyContent='center' style={{ paddingBottom: 5 }} onMouseEnter={openTooltip} onMouseLeave={closeTooltip} onClick={handleClick} className={classes.avatarContainer}>
            <Avatar className={[classes.avatar, activeClass ? classes.avatarActive : null]} id='verih-avatar' src={avatar} />
            <Typography variant='body1' style={{ fontWeight: 700, color:"#011144" }}>Suporte</Typography>
          </Grid>
        </Tooltip>
        <Grid container item justifyContent='center'>
          <Divider orientation='horizontal' width="80%" />
        </Grid>
        <Grid container item justifyContent='center' id='handle' className={classes.containerHandle} >
          <FontAwesomeIcon icon={faGripHorizontal} fontSize='large' />
        </Grid>
      </Grid>
    </Draggable>
  )
}

export default SupportPop
