import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge';

const stub = (state = initialState.stub, action) => {
  switch (action.type) {
    case types.STUB_LIST_IS_LOADED: {
      let stub = {
        ...state,
        items: action.items
      };
      return merge(state.stub, stub);
    }
    case types.STUB_LIST_TELEPHONE: {
      let stub = {
        ...state,
        telephone: action.telephone
      };
      return merge(state.stub, stub);
    }
    default:
      return state;
  }
};

export default stub;