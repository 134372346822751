/**
 * Route constants
 */
export const TELEPHONE_PATH = '/stub/telephone';
export const STUB_PATH = '/stub/list';
export const CUSTOM_FIELD_PATH = '/stub/custom-fields';
export const PENDENCIES = '/stub/pendencies';
export const APPS_PATH = '/stub/apps';
export const CONFIGURATION_PATH = '/stub/config';
export const ABOUT_PATH = '/about';

export const LOGIN_PATH = '/login';
export const HOME_PATH = '/';

// Welcome (Is first route when user is logged)
export const WELCOME_PATH = TELEPHONE_PATH;
