// Table.js (Componente filho)
import { Button, Table, TableBody, TableRow, TableCell, TableHead, makeStyles, TableContainer } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Input from "../../../containers/form/Input";

const OptionTableRow = ({ value, nameValue, index, removeRow, handleInputChange }) => {
  return (
    <TableRow>
      <TableCell>
        <Input label="Nome" value={nameValue} onChange={(e) => handleInputChange(index, 'name', e.target.value)} />
      </TableCell>
      <TableCell>
      <Input label="Valor" value={value} onChange={(e) => handleInputChange(index, 'value', e.target.value)} />
      </TableCell>
      <TableCell>
        <Button variant="contained" color="default" size="small" 
                onClick={() => removeRow(index)}>
            Remover
        </Button>
      </TableCell>
    </TableRow>
  );
};

const OptionsTable = ({ defaultRows, onRowsChange }) => {
  const [rows, setRows] = useState([{ name: '', value: '' }]);

  useEffect(() => {
    setRows(defaultRows);
    console.log(defaultRows)
  
  }, [defaultRows]);

  const addRow = () => {
    setRows([...rows, { name: '', value: '' }]);
  };

  const removeRow = (indexToRemove) => {
    setRows(rows.filter((_, index) => index !== indexToRemove));
    onRowsChange(rows.filter((_, index) => index !== indexToRemove));
  };

  const handleInputChange = (index, fieldName, fieldValue) => {
    const updatedRows = rows.map((row, rowIndex) => {
      if (rowIndex === index) {
        return { ...row, [fieldName]: fieldValue };
      }
      return row;
    });
    setRows(updatedRows);
    onRowsChange(updatedRows);
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    tableCell: {
      fontSize: '1.8rem',
    },
  });
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>Nome</TableCell>
            <TableCell className={classes.tableCell}>Valor</TableCell>
            <TableCell>
                <Button variant="contained" color="primary" size="small" onClick={addRow}>Nova Opção</Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <OptionTableRow
              value={row.value}
              nameValue={row.name}
              key={index}
              index={index}
              removeRow={removeRow}
              handleInputChange={handleInputChange}
            />
          ))}
        </TableBody>
      </Table>
      <br />
    </TableContainer>
  );
};

export default OptionsTable;
