/**
 * File utils
 */
class FileUtils {
  downloadFile(fileURL, fileName) {
    if (!window.ActiveXObject) {
      var save = document.createElement('a');
      save.href = fileURL;
      save.target = '_blank';
      var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
      save.download = fileName || filename;
      if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
        document.location = save.href;
      } else {
        var evt = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': false
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, '_blank');
      _window.document.close();
      _window.document.execCommand('SaveAs', true, fileName || fileURL)
      _window.close();
    }
  }

  /**
   * Get the mime type from base64 file string
   * @param {string} encoded - Base64 file String
   * @return string
   */
  getBase64MimeType(encoded) {
    var result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

  getExtension(mimeType) {
    switch (mimeType) {
      case 'application/pdf':
        return '.pdf';
      case 'application/zip':
        return '.zip';
      case 'application/xml':
        return '.xml';
      case 'application/sql':
        return '.sql';
      case 'application/json':
        return '.json';
      case 'image/png':
        return '.png';
      case 'application/x-pkcs12':
        return '.pfx';
      default:
        throw new Error('Mime type is not supported!');
    }
  }

  typeIsValid(fileName, types = []) {
    let validFileExtensions = types;
    let isValid = false;
    for (let j = 0; j < validFileExtensions.length; j++) {
      let validFileExtension = validFileExtensions[j];
      let extension = fileName.substr(fileName.length - validFileExtension.length, validFileExtension.length);
      if (extension.toLowerCase() === validFileExtension.toLowerCase()) {
        isValid = true;
        break;
      }
    }
    return isValid;
  }
  /**
   * Return base64 string from file object
   * @param {object} file 
   * @return string
   */
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}
export default FileUtils;

