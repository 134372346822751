import React from 'react'
import { Button, FormControl, Grid, TextField, makeStyles } from '@material-ui/core'
import Vmodal from './modal/Vmodal';



const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 500,
        color: "#2a3566"
    }
}));

function ForgotPassword({ isOpen, closeAction, emails, handleChange, sendEmails }) {

    const classes = useStyles();

    const ButtonCustomized = () => {
        return (
            <>
                <Button onClick={sendEmails} color="primary" variant='contained' size='small'>
                    Enviar
                </Button>
            </>
        )
    }

    return (
        <Vmodal
            title="Recuperar a Senha"
            isOpen={isOpen}
            closeAction={closeAction}
            componentButton={<ButtonCustomized />}
        >
            <Grid container xs={12} sm={12} md={12} lg={12} spacing={2} alignItems="center" justifyContent='center'>
                <Grid container item xs={6} sm={6} md={6} lg={6} style={{ marginBlock: 30 }}>
                    <FormControl variant="filled" fullWidth size='small'>
                        <TextField
                            autoComplete="emails"
                            name="emails"
                            value={emails}
                            variant="filled"
                            fullWidth
                            id="emails"
                            label="Email"
                            autoFocus
                            size='small'
                            onChange={handleChange}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Vmodal>
    )
}

export default ForgotPassword