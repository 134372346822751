import React, { Component, Fragment } from 'react';

import { 
  Grid, Typography,  TextField, 
  Box,  FormControl, InputLabel, Select, MenuItem, Button
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import Image from '../../../../assets/images/modelo01.png';

const styles = {
  paperContainer: {
      backgroundImage: `url(${Image})`,
      width:250, 
      height:480,
  }
};

class MobileExample extends Component {
  constructor(props) {
    super(props);   
  }
  render() {

    const { isEnabledStatusEntry, isEnabledObservation, inputStatusEntry, inputObservation} = this.props; 

    return (
        <Fragment>
            <Box style={{marginTop:20, marginBottom:55}}>
                <Grid item md={12}>
                    <Typography style={{ fontSize:16, fontWeight:400, marginBottom:-15, marginLeft:35}}> Veja o exemplo abaixo:</Typography>
                </Grid>                     
                <Box style={styles.paperContainer} >
                    <Grid  container  direction="row" justifyContent="center" alignItems="center" style={{width:250, height:480}} >
                        <Grid  container item direction="row" justifyContent="flex-start" alignItems="flex-start" style={{width:170, height:300, backgroundColor:'#ffffff', opacity:0.9, marginTop:-15, marginLeft:8}}>
                            <Grid item direction="column"  justifyContent='flex-start' alignItems="flex-start" md={12} style={{backgroundColor:'blue'}}>
                                <Grid md={12} style={{backgroundColor:'blue'}} direction="row" justifyContent='center' alignItems="center" >
                                    <Typography style={{fontSize:11, color:"#ffffff", fontWeight:'bold', textAlign:'center', padding:10}}>
                                            <ArrowBackIcon style={{color:"#ffffff", fontSize:16}}/>DADOS DO CANHOTO
                                    </Typography>
                                </Grid>                                    
                            </Grid>               
                                { isEnabledStatusEntry &&
                                    <Grid container item direction="column" md={12} style={{padding:15, marginBottom:1, marginTop:-10}}>                                      
                                        <Grid item md={12}>                                                                     
                                            <FormControl>
                                                <InputLabel id="demo-simple-select-label" style={{fontSize:12, fontWeight:500}}>{inputStatusEntry == ''? 'Status da Entrega' : inputStatusEntry}</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"                                                                                          
                                                >                                       
                                                <MenuItem value={'ENTREGA TOTAL'}>Entrega Total</MenuItem>
                                                <MenuItem value={'ENTREGA PARCIAL'}>Entrega Parcial</MenuItem>
                                                <MenuItem value={'NAO ENTREGUE'}>Não Entregue</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }
                            { isEnabledObservation &&
                                <Grid item direction="column" md={12} style={{padding:15}}>
                                    <Grid md={12}>
                                        <Typography style={{color:"#6b6b6b", fontSize:12, fontWeight:500}}>
                                            {inputObservation ==''?'Observação': inputObservation}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12}>                                            
                                        <TextField size="small"                                                                                                                       
                                        />                                           
                                    </Grid>
                                </Grid>                      
                            }
                            <Grid container item direction="row" justifyContent="center" alignItems="center" md={12}  style={{marginTop:-4}}>
                                <Button 
                                    size='small'                                      
                                    disableElevation                                   
                                    variant="contained"                                         
                                    color="primary"
                                    endIcon={<PhotoCamera style={{color:"#ffffff"}} />}>
                                        Capturar
                                </Button>   
                            </Grid>                            
                        </Grid>                   
                    </Grid>                    
                </Box>
            </Box>
      </Fragment>
    );
  }
}

export default MobileExample;
