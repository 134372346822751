import * as routes from '../constants/routes';
export default {
  menu: {
    'home': {
      text: 'Início',
      path: routes.HOME_PATH,
      icon: 'fas fa-home'
    },
    'stub/telephone': {
      text: 'Telefones',
      path: routes.TELEPHONE_PATH,
      icon: 'fas fa-mobile-alt'
    },
    'stub/list': {
      text: 'Canhotos',
      path: routes.STUB_PATH,
      icon: 'far fa-file-alt'
    },
    'stub/custom-field': {
      text: 'Campos Personalizados',
      path: routes.CUSTOM_FIELD_PATH,
      icon: 'fa fa-solid fa-list'
    },
    'stub/pendencies': {
      text: 'Pendências de Canhoto',
      path: routes.PENDENCIES,
      icon: 'fas fa-truck'
    },
    'stub/config': {
      text: 'Configurações',
      path: routes.CONFIGURATION_PATH,
      icon: 'fa fa-cogs'
    },
    'about': {
      text: 'Sobre',
      path: routes.ABOUT_PATH,
      icon: 'fa fa-info-circle'
    }
  }
};
