import { connect } from 'react-redux';
import LoadingSpinner from '../components/LoadingSpinner';

const mapStateToProps = (state, ownProps) => ({
    visible: ownProps.visible
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingSpinner);
