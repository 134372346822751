import React from 'react'
import { Grid, Link, makeStyles } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';

const useStyles = makeStyles((theme) => ({

  linkIcon: {
    fontSize:40,
    "&:hover": {
      color: "#3a0b91",
    },
  },
  linkIconSpotify:{
    fontSize:33,  
    "&:hover": {
      color: "#3a0b91",
    },

  }

}));


function SocialMidia() {

  const classes = useStyles()

  return (
    <>
      <Grid container item xs={12} justifyContent='center'>
        <Grid container item xs={6} justifyContent="space-around">
          <Link href='https://www.facebook.com/varitusbrasil' target="_blank" rel="noopener"  >
            <FacebookIcon fontSize='large' color='primary' className={classes.linkIcon} />
          </Link>
          <Link href='https://www.instagram.com/varitusbrasil/' target="_blank" rel="noopener">
            <InstagramIcon fontSize='large' color='primary' className={classes.linkIcon} />
          </Link>
          <Link href='https://www.linkedin.com/company/varitus/' target="_blank" rel="noopener">
            <LinkedInIcon fontSize='large' color='primary' className={classes.linkIcon} />
          </Link>
          <Link href='https://api.whatsapp.com/send?phone=551935080545' target="_blank" rel="noopener">
            <WhatsAppIcon fontSize='large' color='primary' className={classes.linkIcon} />
          </Link>
          <Link href='https://www.youtube.com/channel/UCPV11pxvUM9Zg7dsyuF3FJg' target="_blank" rel="noopener">
            <YouTubeIcon fontSize='large' color='primary' className={classes.linkIcon} />
          </Link>

          <Link href='https://twitter.com/nfevaritus' target="_blank" rel="noopener">
            <TwitterIcon fontSize='large' color='primary' className={classes.linkIcon} />
          </Link>

          <Link href='https://open.spotify.com/show/5aGAtzb8LtVPdFtWN4p691' target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faSpotify}  color='primary' className={classes.linkIconSpotify}  />
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

export default SocialMidia