import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import CustomFieldApi from "../../../api/CustomFieldApi";

const dialogDelete = (open, ids = [], onClose, onStart, onFinish) => {

    function deleteCustomFiels() {
        onStart()
        const deletePromises = ids.map((id) => CustomFieldApi.deleteCustomField(id));
        Promise.all(deletePromises).then(() => onFinish());
    }

    return (
        <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Atenção
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"Tem certeza que deseja deletar " + (ids.length == 1 ? "este campo?" : "estes campos?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Voltar
                    </Button>
                    <Button onClick={deleteCustomFiels} color="primary" autoFocus>
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

export default dialogDelete;