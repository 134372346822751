import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import userProfileUtils from "../utils/userProfileUtils";
import axios from 'axios';
import React from "react";
import StubFilterUtils from "../utils/stubFilterUtils";

class GeocodeApi {

    static getGeolocation(lat, lng) {

        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: apiEndpoints.REVERSE_GEOCODING(lat, lng),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON}
        }).then(response => {
            return response;
        }).catch(error => {
            return "";
        });
    }

}

export default GeocodeApi;