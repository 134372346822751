import { connect } from 'react-redux'
import MainContent from '../components/MainContent'

const mapStateToProps = (state, ownProps) => ({
  title: ownProps.title, 
  breadCrumbs: ownProps.breadCrumbs,
  innerContent: ownProps.innerContent,
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContent)
