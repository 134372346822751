import React from 'react';

function TruncateText(props) {
    const { text, limit } = props;

    if (text.length > limit) {
        const truncatedText = text.substring(0, limit) + '...';
        return String(truncatedText)
    }

    return String(text)
}

export default TruncateText;