import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from '../../containers/grid/Grid';
import LoadingSpinner from '../../containers/LoadingSpinner';
import * as global from '../../constants/global';
import telephoneApi from '../../api/telephoneApi';
import notificationUtils from '../../utils/notificationUtils';
import {withStyles} from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import BusinessIcon from '@material-ui/icons/Business';
import LockIcon from '@material-ui/icons/Lock';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {ValidatorForm} from "react-material-ui-form-validator";
import Input from "../../containers/form/Input";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import ValidationUtils from "../../utils/validationUtils";
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputUtils from "../../utils/inputUtils";
import menuConfig from "../../config/menu";
import StubApi from "../../api/stubApi";
import { Checkbox, FormControlLabel, Typography, InputAdornment, IconButton } from '@material-ui/core';
import CanhotoTelephoneLocation from "./CanhotoTelephoneLocation";
import Image from "../../../assets/images/no-location-phone.png";

import { Visibility, VisibilityOff } from '@material-ui/icons';

class TelephoneManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollDownCurrentLimit: global.MAX_RECORDS_PER_PAGE,
            showLoadingSpinner: this.props.items.length == null,
            filters: {},
            items: this.props.items,
            onForm: false,
            isFormLoading: false,
            isDialogOpen: false,
            isDialogLocationOpen: false,
            location: null,
            pendencies: null,
            selectedPhone: null,
            isEditing: false,
            editingPhoneId: null,
            telephone: {
                nome: "",
                phone: "",
                document: "",
                email: "",
                pin: "",
                authorizedCnpjs: [],
                tempCnpj: '',
                allowedAllCnpj: false,
                showLocation: false,
            }, 
            showPin: false,         
        };
    }

    defaultBlankTelephone = {
        nome: "",
        phone: "",
        document: "",
        email: "",
        pin: "",
        authorizedCnpjs: [],
        tempCnpj: '',
        allowedAllCnpj: false,
        showLocation: false,
    }

    clearListCnpj(){    
        const { authorizedCnpjs } = this.state.telephone;
        this.setState({
            ...this.state,
            telephone:{
                ...this.state.telephone,
                authorizedCnpjs: []
            }

        })   
        
    }
    
    componentDidMount() {
        ValidatorForm.addValidationRule('isValidDocument', (value) => {
            return ValidationUtils.valida_cpf_cnpj(value);
        });
        ValidatorForm.addValidationRule('isValidCnpj', (value) => {
            return ValidationUtils.valida_cnpj(value);
        });
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isValidDocument');
        ValidatorForm.removeValidationRule('isValidCnpj');
    }

    handleInputsForm = (evt) => {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        this.setState({
            ...this.state,
            [evt.target.name]: value
        }); 
    } 

    refreshTelephones = (onSuccess, onFail) => {
        telephoneApi
            .filterBy()
            .then(response => {
                let message = response.message;
                if (message.success) {
                    onSuccess(response);
                } else {
                    onFail(response);
                }
            });
    }

    handleChange = (event, type) => {       
        let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        const { allowedAllCnpj } = this.state.telephone       
        if(allowedAllCnpj){
            this.clearListCnpj()
        }  

        this.setState(state => ({
            ...state,
            telephone: {
                ...state.telephone,
                [type]: value
            }
        }));
    };

    handleValueChange = (value, type) => {
        this.setState(state => ({
            ...state,
            telephone: {
                ...state.telephone,
                [type]: value
            }
        }));
    };

    handleRemoveFromList = (value, type) => {
        this.setState(state => ({
            ...state,
            telephone: {
                ...state.telephone,
                [type]: this.state.telephone[type].filter(item => item !== value)
            }
        }));
    }

    handleListChange = (value, type) => {
        this.setState(state => ({
            ...state,
            telephone: {
                ...state.telephone,
                [type]: [...this.state.telephone[type], value]
            }
        }));
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            items: nextProps.items,
            showLoadingSpinner: false,
        });
    }

    handleLoadingSpinner = value => {
        this.setState({
            showLoadingSpinner: value,
        });
    };

    removeIdFromTelephoneList(id) {
        let {items} = this.state;

        const index = items.findIndex(item => item.id == id);
        items.splice(index, 1);
        this.setState({
            ...this.state,
            items: items
        });

        this.handleLoadingSpinner(false);
    }

    deleteSingleTelephone(id) {
        this.handleLoadingSpinner(true);
        telephoneApi.deleteTelephone(id).then(response => {
            const message = response.message;
            if (message.success) {
                notificationUtils.success("Telefone deletado com sucesso");
                this.removeIdFromTelephoneList(id);
            } else {
                notificationUtils.warn(message.details);
                this.handleLoadingSpinner(false);
            }
        });
    }

    deleteTelephoneList(selectedRows) {
        this.handleLoadingSpinner(true);
        selectedRows.forEach(item => this.deleteSingleTelephone(item[0]));
    }

    loadTelephoneStubs(fone){
        StubApi.filterBy({telephone: fone}).then(response => {
            this.handleLoadingSpinner(true);
            const message = response.message;
            if (message.success || message.details === "Nenhum registro foi encontrado com os parâmetros informados.") {
                this.props.loadStubPhone(fone);
                this.props.loadStubGrid(response.stubs);

                const path = menuConfig.menu["stub/list"].path;
                this.props.changePage(path);
            } else {
                notificationUtils.warn(message.details);
                this.handleLoadingSpinner(false);
            }
        });
    }

    buildGrid() {
        let {items} = this.state;

        const columns = [
            {
                name: "id",
                options: {
                    display: 'excluded',
                    viewColumns: "false"
                }
            }, {
                name: "name",
                label: "Nome"
            }, {
                name: "phoneNumber",
                label: "Telefone",
                options: {
                    customBodyRender: value => <div>{InputUtils.formatPhone(value)}</div>
                }
            }, {
                name: "megabytes",
                label: "Megabytes",
                options: {
                    customBodyRender: value => <div>{InputUtils.formatDouble(value)}</div>
                }
            }, {
                name: "document",
                label: "Documento",
                options: {
                    customBodyRender: value => <div>{InputUtils.formatDocument(value)}</div>
                }
            }, {
                name: "email",
                label: "E-mail",
                options: {
                    display: "false"
                }
            }, {
                name: "actions",
                label: "Ações",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    viewColumns: false,
                    download: false,
                    customBodyRender: (value, tableMeta) => {
                        return (<div>
                            <Tooltip title='Editar telefone'>
                                <EditIcon style={{cursor: 'pointer', marginRight: '6px'}} onClick={() => {
                                    this.goToForm([tableMeta.rowData[0]][0]);
                                }}>
                                </EditIcon>
                            </Tooltip>
                            <Tooltip title='Desvincular telefone'>
                                <DeleteIcon style={{cursor: 'pointer', marginRight: '10px'}} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        selectedPhone: [tableMeta.rowData]
                                    }, () => this.handleOpenDialog(true));
                                }}>
                                </DeleteIcon>
                            </Tooltip>
                            <Tooltip title='Visualizar canhotos'>
                                <i style={{cursor: 'pointer', marginRight: '10px'}} className={"far fa-file-alt"} onClick={() => {
                                    this.loadTelephoneStubs(tableMeta.rowData[2]);
                                }}>
                                </i>
                            </Tooltip>
                            {(tableMeta.rowData[8] && <Tooltip title='Visualizar localização'>
                                <i style={{cursor: 'pointer'}} className={"fas fa-map-marker-alt"} onClick={() => {
                                    let phoneId = tableMeta.rowData[7];
                                    let pendenciesPromisse = telephoneApi.getPendenciesFromPhone(phoneId);
                                    let locationPromisse = telephoneApi.findLastLocation(phoneId);
                                    Promise.all([pendenciesPromisse, locationPromisse])
                                        .then((r) => {
                                            this.handleOpenLocationDialog(
                                                true,
                                                r[1].data,
                                                r[0].data,
                                            );
                                        });
                                }}>
                                </i>
                            </Tooltip>)}
                        </div>);
                    }
                }
            }, {
                name: "phoneId",
                options: {
                    display: 'excluded',
                    viewColumns: "false"
                }
            }, {
                name: "showLocation",
                options: {
                    display: 'excluded',
                    viewColumns: "false"
                }
            },
        ];

        const options = {
            searchPlaceholder: 'Pesquisar telefones',
            customToolbarSelect: (selectedRows, displayData) => (
                <div style={{margin: "0 15px"}}>
                    <Tooltip title='Desvincular telefone'>
                        <DeleteIcon style={{cursor: 'pointer'}} onClick={() => {
                            let arr = [];
                            const deletableItems = selectedRows.data.map(item => displayData[item.index]);
                            deletableItems.forEach(item => {
                                arr.push([item.data[0], item.data[1], item.data[2].props.children, item.data[3].props.children, item.data[4].props.children, item.data[5], item.data[6].props.children]);
                            });
                            this.setState({
                                ...this.state,
                                selectedPhone: arr
                            }, () => this.handleOpenDialog(true));
                        }}>
                        </DeleteIcon>
                    </Tooltip>
                </div>
            ),
        };

        return (
            <Grid
                title={"Listagem de Telefones"}
                data={items}
                columns={columns}
                options={options}
            />
        );
    }

    goToForm(editId = null) {
        this.setState({
            ...this.state,
            onForm: !this.state.onForm,
            telephone: this.defaultBlankTelephone
        }, () => {
            this.refs.form.resetValidations();
            if (this.state.onForm == false) {
                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        showLoadingSpinner: true
                    });                 
                    const onSuccess = (response) => {
                        notificationUtils.success(response.message.details);
                      this.setState({
                          ...this.state,
                          items: response.companiesPhones,
                          showLoadingSpinner: false,
                          isEditing: false,
                          editingPhoneId: null,
                      });
                    };
                    const onFail = (response) => {
                        notificationUtils.warn(response.message.details);
                      this.setState({
                          ...this.state,
                          items: [],
                          showLoadingSpinner: false,
                          isEditing: false,
                          editingPhoneId: null,
                      });
                    };
                    this.refreshTelephones(onSuccess, onFail);                  
                }, 2000);
            } else if (this.state.onForm && (editId !== null)) {
                this.setState({
                    ...this.state,
                    showLoadingSpinner: true,
                }, () => {
                    telephoneApi.retrieve(editId).then(response => {
                        const phone = {
                            nome: response.name || '',
                            phone: InputUtils.formatPhone(response.phoneNumber),
                            document: InputUtils.formatDocument(response.document),
                            email: response.email || '',
                            pin: response.pin || '',
                            authorizedCnpjs: response.authorizedDocuments.map(item => InputUtils.formatCnpj(item.document)) || [],
                            tempCnpj: '',
                            allowedAllCnpj: response.allowedAllCnpj,
                        };
                        console.log("*****")
                        console.log(phone)
                        console.log("*****")
                        this.setState({
                            ...this.state,
                            showLoadingSpinner: false,
                            isEditing: true,
                            editingPhoneId: editId,
                            telephone: phone
                        });
                    }).catch(() => {
                        notificationUtils.error("API de canhotos indisponível!");
                    });
                });
            }
        });
    }

    saveTelephone() {
        this.setState({
            ...this.state,
            isFormLoading: true
        });

        const handleSuccess = () => {
            this.setState({
                ...this.state,
                isFormLoading: false,
                telephone: this.defaultBlankTelephone
            }, () => this.refs.form.resetValidations());        
            const onSuccess = (response) => {
                this.props.loadTelephoneGrid(response.companiesPhones);              
            };
            const onFail = (response) => {                
                notificationUtils.warn(response.message.details);
                this.props.loadTelephoneGrid([]);
            };
            this.refreshTelephones(onSuccess, onFail);          
        };

        const handleRejection = (message) => {
            this.setState({
                ...this.state,
                isFormLoading: false,
            });
            notificationUtils.warn(message.details);
        };

        const handleError = (message) => {
            this.setState({
                ...this.state,
                isFormLoading: false,
            });
            notificationUtils.error(message);
        };

        if(this.state.isEditing) {
            telephoneApi
                .updateTelephone(this.state.telephone, this.state.editingPhoneId)
                .then(response => {
                    const message = response.message;
                    if (message.success) {
                        notificationUtils.success("Telefone atualizado com sucesso");
                        handleSuccess();
                        this.goToForm();                       
                    } else {
                        handleRejection(message);
                    }
                });
        } else {
            telephoneApi
                .saveTelephone(this.state.telephone)
                .then(response => {
                    const message = response.message;
                    if (message.success) {
                        notificationUtils.success("Telefone salvo com sucesso");
                        handleSuccess();
                    } else {
                        handleRejection(message);
                    }
                });
        }
    }

    buildNewStubForm() {
        let {nome, phone, document, email, pin, authorizedCnpjs, tempCnpj, allowedAllCnpj} = this.state.telephone;
        let {isFormLoading, showPin} = this.state;

        return (
            <div className={'telephone__form ' + (this.state.onForm ? 'telephone__form--slide' : '')}>
                <h1> Adicionar Telefone </h1>
                <ValidatorForm
                    ref="form"
                    noValidate
                    className="telephone__validator"
                    autoComplete="new-password"
                >
                    <div>
                        <PersonIcon className="telephone__form--icon"/>
                        <Input
                            validators={['required']}
                            errorMessages={['Este campo é obrigatório']}
                            label={<span>Nome <span style={{ color: 'red' }}>*</span></span>}
                            onChange={e => this.handleChange(e, 'nome')}
                            value={nome}
                            autoComplete="random-string2"
                            disabled={isFormLoading}
                        />
                    </div>
                    <div>
                        <PhoneIcon className="telephone__form--icon"/>
                        <Input
                            validators={['required', 'minStringLength:15']}
                            errorMessages={['Este campo é obrigatório', 'Numero de celular incompleto']}
                            label={<span>Telefone <span style={{ color: 'red' }}>*</span></span>}
                            mask="phone"
                            onChange={e => this.handleChange(e, 'phone')}
                            value={phone}
                            autoComplete="random-string3"
                            disabled={isFormLoading}
                        />
                    </div>
                    <div>
                        <AssignmentRoundedIcon className="telephone__form--icon"/>
                        <Input
                            validators={['required', 'isValidDocument']}
                            errorMessages={['Este campo é obrigatório', 'CPF / CNPJ inválido']}
                            label={<span>CPF / CNPJ <span style={{ color: 'red' }}>*</span></span>}
                            mask="document"
                            onChange={e => this.handleChange(e, 'document')}
                            value={document}
                            autoComplete="random-strin4g"
                            disabled={isFormLoading}
                        />
                    </div>
                    <div>
                        <MailIcon className="telephone__form--icon"/>
                        <Input
                            validators={['required', 'isEmail']}
                            errorMessages={['Este campo é obrigatório', 'E-mail inválido']}
                            label={<span>E-mail <span style={{ color: 'red' }}>*</span></span>}
                            onChange={e => this.handleChange(e, 'email')}
                            value={email}
                            autoComplete="rando6m-string"
                            disabled={isFormLoading}
                        />
                    </div>
                    <div>
                        <LockIcon className="telephone__form--icon"/>
                        <Input
                            validators={['matchRegexp:(^[0-9]{4,}$|^[0-9]{0}$)']}
                            errorMessages={['Minimo de 4 digitos']}
                            label="PIN"
                            type={showPin ? 'text' : 'password'}
                            onChange={e => this.handleChange(e, 'pin')}
                            value={pin}
                            autoComplete="rando11-string"
                            disabled={isFormLoading}
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton onClick={() => this.setState({showPin: !showPin})}>
                                    {showPin ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }} 
                        />
                    </div>
                    {!allowedAllCnpj &&
                        <div>
                            <BusinessIcon className="telephone__form--icon"/>
                            <Tooltip title={
                                <Typography>
                                    Informar os CNPJs das empresas destinatárias cujo este procurador deverá assinar os canhotos.
                                    <br />
                                    <br />
                                    Este telefone não é rastreado.
                                    <br />
                                    Para adicionar um item na lista pressione espaço ou enter.
                                </Typography>
                            }>

                                <Input
                                
                                    label={<p className="telephone__form--tooltip" style={{fontSize:12}}>CNPJ(s) permitidos para emissão de canhotos<HelpIcon/></p>}
                                    mask="cnpj"
                                    onChange={e => this.handleChange(e, 'tempCnpj')}
                                    validators={['isValidCnpj']}
                                    errorMessages={['CNPJ inválido']}
                                    value={tempCnpj}
                                    autoComplete="random-strin24g"
                                    onKeyDown={e => {
                                        const allowedKeys = ['Enter', ','];
                                        const { tempCnpj, authorizedCnpjs } = this.state.telephone;
                                    
                                        if (allowedKeys.includes(e.key)) {
                                          
                                          if (!tempCnpj.trim()) {
                                            notificationUtils.warn('O campo CNPJ não pode estar vazio.');
                                            return;
                                          }
                                    
                                          
                                          if (!ValidationUtils.valida_cnpj(tempCnpj)) {
                                            notificationUtils.warn("CNPJ inválido. Corrija o CNPJ para adicioná-lo à lista.");
                                            return;
                                          }
                                    
                                          
                                          if (authorizedCnpjs.includes(tempCnpj)) {
                                            notificationUtils.warn("CNPJ já se encontra na lista.");
                                            return;
                                          }
                                    
                                          
                                          this.handleListChange(tempCnpj, 'authorizedCnpjs');
                                          this.handleValueChange('', 'tempCnpj');
                                        }
                                      }}
                                      disabled={isFormLoading}
                                    />
                            </Tooltip>
                        </div>
                    
                    }
               
                    {/* aqui criar o check */}
                    <div style={{justifyContent:'right', alignItems:'center', width:'100%', paddingRight:'0px'}} >  
                        <div style={{ display: 'inline-flex',width:'50%', paddingRight:'0px'}} >
                            <FormControlLabel
                                control={<Checkbox  onChange={e => this.handleChange(e, 'allowedAllCnpj')} value={allowedAllCnpj}  checked={allowedAllCnpj} type="checkbox" />}
                            />
                            <Typography style={{fontSize:12, color:"#6c6b6b", textAlign:'left'}} align="center">
                                Permitir todos os CNPJ(s) para emissão de canhotos?
                            </Typography>

                            <Tooltip title="Permitir que este telefone receba como pendência qualquer documento emitido pela empresa">
                                <HelpIcon fontSize="medium" />
                            </Tooltip>
                        </div>                          
                    </div>
                   
                        

                        {
                            (authorizedCnpjs.length > 0 && !allowedAllCnpj) && 
                            <div className="telephone__form--cnpjs">
                                <h5>CNPJ(s) permitidos para as notificaçoes:</h5>
                                {authorizedCnpjs.map((option, index) => (
                                    <Chip key={index} variant="outlined" label={option} onDelete={() => this.handleRemoveFromList(option, 'authorizedCnpjs')}/>
                                ))}
                            </div>

                        }

                    <div className="telephone__form--button">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            // type="submit"
                            disabled={this.isFormDisabled()}
                            onClick={() => {
                                this.saveTelephone();
                            }}
                            fullWidth>
                            {isFormLoading ?
                                <CircularProgress size={25} style={{color: 'white', marginRight: '12px'}}/> :
                                <CheckIcon style={{
                                    color: this.isFormDisabled() ? '#a6a6a6' : 'white',
                                    marginRight: '12px'
                                }}/>} 
                                {this.state.isEditing ? 'Editar' : 'Salvar'}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        );
    }

    isFormDisabled() {
        let {nome, phone, document, email, pin} = this.state.telephone;
        return (nome.length == 0 || phone.length == 0 || document.length == 0 || email.length == 0 || (pin.length > 0 && pin.length < 4));
    }

    buildNewStub() {
        const StyledIcon = withStyles({
            root: {
                color: "#ffffff",
                fontSize: "1.7vw"
            }
        })(AddIcon);

        return (
            <Tooltip title={!this.state.onForm ? "Adicionar novo telefone" : "Retornar a listagem"}>
                <Fab color="primary"
                     aria-label="add"
                     onClick={() => this.goToForm()}
                     className={"telephone__floating telephone__floating--icon " + (this.state.onForm ? 'telephone__floating--icon-rotate' : '')}>
                    <StyledIcon></StyledIcon>
                </Fab>
            </Tooltip>

        );
    }

    handleOpenDialog(value) {
        this.setState({
            ...this.state,
            isDialogOpen: value
        });
    }

    handleOpenLocationDialog(value, location, pendencies) {
        this.setState({
            ...this.state,
            isDialogLocationOpen: value,
            location: location,
            pendencies: pendencies,
        });
    }

    buildDialogText(count, selectedPhone) {
        let textArray = [];

        selectedPhone.forEach(item => {
            textArray.push(<p>
                Telefone de <b>{item[1]}</b>, documento <b>{InputUtils.formatDocument(item[4])}</b> e número <b>{InputUtils.formatPhone(item[2])}</b>
            </p>);
        });

        return textArray;
    }

    buildLocationDialog() {
        const {isDialogLocationOpen, location, pendencies} = this.state;

        if (!isDialogLocationOpen)
            return;

        console.log(location);
        return (
            <Dialog
                fullWidth
                maxWidth="md"
                scroll={'paper'}
                open={isDialogLocationOpen}
                onClose={() => this.handleOpenLocationDialog(false, null)}
                aria-labelledby="alert-dialog-location-title"
                aria-describedby="alert-dialog-location-description"
            >
                <DialogTitle id="alert-dialog-location-title" className={'text-center'}>
                    {(location && 'Última localização do telefone')}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText id="alert-dialog-location-description" style={{maxHeight: 700, overflowY: "auto"}}>
                        {(pendencies.length > 0 ?
                                <div>
                                    {location &&
                                        <h3>
                                            Última atualização: {InputUtils.formatFullDate(location.dhCriacao)}
                                        </h3>
                                    }
                                    <CanhotoTelephoneLocation
                                        latitude={location != null ? location.latitude : null}
                                        longitude={location != null ? location.longitude : null}
                                    />

                                    <hr/>
                                    <h3 className='text-center'>Documentos fiscais</h3>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Série</th>
                                            <th scope="col">Número</th>
                                            <th scope="col">Tipo</th>
                                            <th scope="col">Data emissão</th>
                                            <th scope="col">Destinatário</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {pendencies.map((pen, index) => (
                                            <tr key={index}>
                                                <td>{pen.series}</td>
                                                <td>{pen.number}</td>
                                                <td>{pen.documentType}</td>
                                                <td>{InputUtils.formatDate(pen.issueDate)}</td>
                                                <td>{pen.recipientName}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className={'text-center'}>
                                    <img className={'text-center'} src={Image} alt="Este telefone não tem pendencia de entrega"
                                         style={{width: 250}}/>
                                    <p className={'text-center'}>Este telefone não tem pendencia de entrega</p>
                                </div>
                        )}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

    buildDialog() {
        const {isDialogOpen, selectedPhone} = this.state;
        if (selectedPhone == null) return;
        const count = selectedPhone.length;

        return (
            <Dialog
                open={isDialogOpen}
                onClose={() => this.handleOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{ "Tem certeza que deseja deletar " + (count == 1 ? "este telefone?" : "estes telefones?")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        {this.buildDialogText(count, selectedPhone)}

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleOpenDialog(false)}>
                        Voltar
                    </Button>
                    <Button onClick={() => {
                        this.setState({
                            ...this.state,
                            isDialogOpen: false
                        }, () => {
                            this.deleteTelephoneList(selectedPhone);
                        });
                    }} color="primary" autoFocus>
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        return (
            <div>
                {this.buildNewStub()}
                {this.buildDialog()}
                {this.buildLocationDialog()}
                <div className={'table__container ' + (this.state.onForm ? 'table__container--slide' : '')}>
                    <div>
                        {this.buildGrid()}
                        <LoadingSpinner visible={this.state.showLoadingSpinner}/>
                    </div>
                    <div>
                        {this.buildNewStubForm()}
                    </div>
                </div>
            </div>
        );
    }
}

TelephoneManage.propTypes = {
    items: PropTypes.array,
    loadTelephoneGrid: PropTypes.func
};

export default TelephoneManage;
