import moment from "moment";
import InputUtils from "../utils/inputUtils";

class StubFilterUtils {

    static formatFilter(filters){
        const {type, startDate, endDate, serie, number, cnpj, telephone, deliveryStatus, searchByRootCnpj} = filters;
        return {
            documentType: this.getTypesString(type),
            issuerCnpjCpf: InputUtils.formatRemoveMask(cnpj),
            series: serie,
            number: number,
            creationStartDate: startDate == null ? new Date() : startDate,
            creationEndDate: endDate == null ? new Date() :  endDate,
            phoneNumber: InputUtils.formatRemoveMask(telephone),
            deliveryStatus: deliveryStatus,
            searchByRootCnpj: searchByRootCnpj
        };
    }

    static formatCsvFilter(filters){
        const { type, startDate, endDate, serie, number, cnpj, telephone, deliveryStatus, searchByRootCnpj } = filters;
        const creationStartDate = startDate == null ? new Date() : startDate;
        const creationEndDate = endDate == null ? new Date() : endDate;
        return {
            documentType: this.getTypesString(type),
            issuerCnpjCpf: InputUtils.formatRemoveMask(cnpj),
            series: serie,
            number: number,
            creationStartDate: new moment(creationStartDate).format('YYYY-MM-DD'),
            creationEndDate: new moment(creationEndDate).format('YYYY-MM-DD'),
            phoneNumber: InputUtils.formatRemoveMask(telephone),
            deliveryStatus: deliveryStatus,
            searchByRootCnpj: searchByRootCnpj
        };
    }

    static getTypesString(type){
        if(type == null) return "";
        let documentTypes = "";
        Object.keys(type)
            .forEach((key) => {
                if (type[key]) {
                    documentTypes += key + ", ";
                }
            });
        return documentTypes.toUpperCase();
    }

    

}

export default StubFilterUtils;