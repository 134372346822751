import React, { Component } from "react";
import PropTypes from "prop-types";

import MUIDataTable from "mui-datatables";
import textLabels from './GridTranslator';

class Grid extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {

    const dataTableProps = {
      ...this.props,
      options: {
        filterType: 'checkbox',
        rowsPerPage: 7,
        rowsPerPageOptions: [7],
        print: false,
        filter: false,
        downloadOptions: {
          filterOptions: {
            useDisplayedColumnsOnly: true
          }
        },
        textLabels,
        ...this.props.options
      }
    };

    return (
        <div className="main__table" style={{width: '72vw'}}>
          <MUIDataTable {...dataTableProps} />
        </div>
    );
  }
}

Grid.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.object,
  options: PropTypes.object
};

export default Grid;