import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import * as global from '../constants/global';
import userProfileUtils from "../utils/userProfileUtils";
import axios from 'axios';


class ParametersApi {

    static getParameters() {
            var origin = 'WEB';
        var base64Credentials = btoa(global.AUTH_PRODUCT_CODE + ':' + global.STUB_API_SECRET);
        // var base64Credentials = "U1RVQjp5dVFwN1RjakxLZlhF";
        console.log("token")
        console.log(base64Credentials)
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;        
        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: apiEndpoints.PARAMETERS_ENDPOINT(origin),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Basic ' + base64Credentials },
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }
}

export default ParametersApi;

