
export default {
  topbar: {
    logoUrl: '',
    cnpj: '',
    companyName: '',
    userName: ''
  },
  leftmenu: {
    items: []
  },
  telephone: {
    items: []
  },
  stub: {
    items: [],
    telephone: ""
  },
  user: {
    userId: null, 
    username: null,
    accessToken: null,
    isFirstAccess: false,
    tokenType: null,
    trialAccessPlanInfo: null,
    refreshToken: null,
    productCodes: []
  }
};
