import React, {Component} from 'react';
import '../../assets/varitusApps.css';
import productsApi from '../../api/productsApi';

class AppsManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          appsStyle: ["divIcon", "modalApps"],
          products: []
        };
      }
    
      componentWillMount = () => {
        productsApi
        .allProducts()
        .then(response => {
          this.setState({
            ...this.state,
            products: response.products
          })
        })
      }

      toggleApps = () => {
        var classes = this.state.appsStyle;
        classes.includes("openModal") ? classes.pop("openModal") : classes.push("openModal");
        this.setState({
          ...this.state,
          appsStyle: classes
        });
      }
     
      redirect = (redirect) => {
        window.open(redirect, "_blank");
      }
    
      generateApp = (img, url, name) => {
        return <div className="subModal" onClick={() => this.redirect(url)}>
    
                {/* <div className="modalImage">
                  <img className="appImage" src={img} />
                </div>
                <p className="iconText">{name}</p> */}
    
                  <div className="modalImage">
                    <img className="appImage" src={img} />
                  </div>
                    <div className="modalText">
                        <p className="iconText">{name}</p>
                    </div>
               </div>
      }
    
      generateApps = () => {
    
        let products = this.state.products;
        const apps = [];
        products = products.filter(value => value.productCode != "STUB_API");
        
        
        products.forEach(product => {
          
          apps.push(this.generateApp(product.logo, product.url, product.description));
        });
    
       return apps;
    
      }

      render() {

        const { appsStyle } = this.state;
    
        return (
          <div class="appConteiner">
           {this.generateApps()}    
          </div>
        );
      }
}

AppsManage.propTypes = {
};

export default AppsManage;