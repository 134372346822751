import React from 'react'

function HomologComponent({title}) {
  return (
    <div style={{backgroundColor: '#ffd3d9', width:'100%', textAlign:'center', color:'#660335', fontWeight:'bold', padding:10, }}>
        <span style={{marginLeft:'130px', fontSize:'18px', padding:10 }}>{title}</span>
    </div>
  )
}

export default HomologComponent