import moment from 'moment';
import initialState from '../reducers/initialState';
import CookiesUtils from './cookiesUtils';
import jwtDecode from "jwt-decode";
class StubProfileUtils {

    static setItem(key, obj) {
        CookiesUtils.setItem(key, JSON.stringify(obj));
    }

    static removeItem(key) {
        CookiesUtils.removeItem(key);
    }

    static getItem(key) {
        let obj = CookiesUtils.getItem(key);
        if (obj !== undefined && obj !== null) {
            return JSON.parse(obj);
        }
        return null;
    }

    static clearAll() {
        this.removeItem('stubProfile');
    }

    static getStubProfile() {
        let stubProfile = this.getItem('stubProfile');
        return stubProfile;
    }

    static getDeliveryStatusLabel(){
        return this.getStubProfile().deliveryStatusLabel;
    }

    static getObservationLabel(){
        return this.getStubProfile().observationLabel;
    }

    static updateStubProfile(customFields){
        this.setItem('stubProfile', customFields);
    }

    static setStubProfile(stubProfile) {
        this.setItem('stubProfile', stubProfile);
    }
}

export default StubProfileUtils;