import React, {Component} from 'react';
import moment from "moment";

import PropTypes from 'prop-types';
import GridDiv from '@material-ui/core/Grid';

import LoadingSpinner from '../../containers/LoadingSpinner';
import * as global from '../../constants/global';
import notificationUtils from '../../utils/notificationUtils';
import Grid from '../../containers/grid/Grid';

import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import PanoramaIcon from '@material-ui/icons/Panorama';

import InputUtils from "../../utils/inputUtils";
import StubApi from "../../api/stubApi";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';
import {ThemeProvider, makeStyles} from '@material-ui/core/styles';
import theme from "../../../stub/Theme";


import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import StubDetailsPdf from "../pdf/StubDetailsPdf";

import {PDFDownloadLink, pdf, View, Text} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GeocodeApi from "../../api/geocodeApi";

import {Checkbox, FormControlLabel, FormGroup, TextField, Button, Select, FormControl, InputLabel, MenuItem, Typography, IconButton} from "@material-ui/core";

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { green, red, yellow } from '@material-ui/core/colors';

import DeliveryStatus from '../../constants/deliveryEnum';
import userProfileUtils from '../../utils/userProfileUtils';
import StubProfileUtils from '../../utils/stubProfileUtils';
import TruncateText from '../TruncateText';
import ModalSimple from '../modal/ModalSimple';
import Vmodal from '../modal/Vmodal';
import { CloudDownload } from '@material-ui/icons';
import FileUtils from '../../utils/fileUtils';

class StubManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollDownCurrentLimit: global.MAX_RECORDS_PER_PAGE,
            showLoadingSpinner: false,
            showFullscreenImage: false,
            showFullscreenTimeout: null,
            showFilter: false,
            telephone: this.props.telephone,
            filters: {
                ...this.initialFilters,
                telephone: this.props.telephone
            },
            items: this.props.items,
            onDetails: false,
            stubDetails: null,
            isFiltered: !!this.props.telephone,
            isDialogOpen: false,
            selectedStub: null,
            isDialogOpenImage: false,
           
        };
    }

    initialFilters = {
        type: {
            nfe: false,
            cte: false,
            nfce: false,
            cfesat: false,
            nfse: false,
            outros: false
        },
        startDate: moment().startOf('day'),
        endDate: moment().startOf('day'),
        serie: null,
        number: null,
        cnpj: null,
        statusDelivery: null,
        searchByRootCnpj: false
    };

    componentDidMount() {
            this.loadData();
            setInterval(this.loadData.bind(this), 600000);
    }

    async loadData() {        
        if (this.state.onDetails || this.state.isFiltered || this.props.telephone ) return;
        this.handleLoadingSpinner(true);
        StubApi.filterBy().then(response => {
            this.handleLoadingSpinner(false);
            let message = response.message;
            if (message.success) {
                this.props.loadStubGrid(response.stubs);
                this.setState({
                    ...this.state,
                    showLoadingSpinner: false,
                });
            } else {
                notificationUtils.warn(message.details);
                this.props.loadStubGrid([]);
                this.setState({
                    ...this.state,
                    showLoadingSpinner: false,
                });
            }
        }).catch(() => {
            this.handleLoadingSpinner(false);
            notificationUtils.error("API de Canhotos indisponível!");
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            items: nextProps.items,
        });
    }

    handleLoadingSpinner = value => {
        this.setState({
            ...this.state,
            showLoadingSpinner: value,
        });
    };

    handleFullscreenImage = value => {
        if (value) {
            this.setState({
                showFullscreenTimeout: setTimeout(() => this.setState({
                    ...this.state,
                    showFullscreenImage: true,
                }), 400)
            });
        } else {
            clearTimeout(this.state.showFullscreenTimeout);
            this.setState({
                ...this.state,
                showFullscreenImage: false,
            });
        }
    };

    removeIdFromStubList(id) {
        let {items} = this.state;

        const index = items.findIndex(item => item.id == id);
        items.splice(index, 1);
        this.setState({
            ...this.state,
            items: items
        });

        this.handleLoadingSpinner(false);
    }

    deleteSingleStub(companyId, stubId) {
        this.handleLoadingSpinner(true);
        StubApi.deleteStub(companyId, stubId).then(response => {
            const message = response.message;
            if (message.success) {
                notificationUtils.success(message.details);
                this.removeIdFromStubList(stubId);
            } else {
                notificationUtils.warn(message.details);
                this.handleLoadingSpinner(false);
            }
        });
    }

    deleteStubList(selectedRows) {
        this.handleLoadingSpinner(true);
        selectedRows.forEach(row => this.deleteSingleStub(row.companyId, row.stubId));
    }

    getStubDetails(stubId) {
        this.handleLoadingSpinner(true);
        StubApi.getDetails(stubId).then(response => {
            const message = response.message;
            if (message.success) {
                notificationUtils.success(message.details);
                this.setState({
                    stubDetails: response.stub,
                    onDetails: true,
                    showFilter: false
                });
            } else {
                notificationUtils.warn(message.details);
            }
            this.handleLoadingSpinner(false);
        });
    }

    getStubImage(stubId) {
        this.handleLoadingSpinner(true);
        StubApi.getDetails(stubId).then(response => {
            const message = response.message;
            if (message.success) {
                notificationUtils.success(message.details);
                this.setState({
                    stubDetails: response.stub,
                    isDialogOpenImage: true,
                    showFilter: false
                });
            } else {
                notificationUtils.warn(message.details);
            }
            this.handleLoadingSpinner(false);
        });
    }

    getStubDetailsForPdf = async(stubId) => {
        this.handleLoadingSpinner(true);
        return StubApi.getDetails(stubId).then(response => {
            const message = response.message;
            if (message.success) {
                const stub = response.stub;
                return stub;

            } else {
                notificationUtils.warn(message.details);
                this.handleLoadingSpinner(false);
                return null;
            }
        });
    };

    getGeolocationForPdf = async(lat, lng) => {
        return GeocodeApi.getGeolocation(lat, lng).then( geoResponse => {
            if(geoResponse.status === 200 && geoResponse.data.status === "OK"){
                return geoResponse.data.results[0].formatted_address;
            } else {
                return "";
            }
        });
    };

    buildGrid() {

        let textTruncate = <TruncateText text={StubProfileUtils.getDeliveryStatusLabel()} limit={15} />
        let deliveryStatusTruncate = textTruncate.props.text
      
        let {items} = this.state;
        const columns = [
            {
                name: "id",
                options: {
                    display: 'excluded',
                    viewColumns: "false"
                }
            }, {
                name: "companyId",
                options: {
                    display: 'excluded',
                    viewColumns: "false"
                }
            }, {
                name: "documentType",
                label: "Tipo",
                options: {
                    customBodyRender: value => <div style={{whiteSpace: "nowrap", fontSize: '14px'}}>{InputUtils.formatStubType(value)}</div>
                },
                viewColumns: false
            }, {
                name: "companyCpfCnpj",
                label: "Empresa",
                options: {
                    customBodyRender: value => <div style={{whiteSpace: "nowrap", fontSize: '14px'}}>{InputUtils.formatDocument(value)}</div>
                }
            }, {
                name: "issuerCnpjCpf",
                label: "Emitente",
                options: {
                    customBodyRender: value => <div style={{whiteSpace: "nowrap", fontSize: '14px'}}>{InputUtils.formatDocument(value)}</div>
                }
            }, {
                name: "series",
                label: "Série",
                options: {
                    customBodyRender: value => <div style={{whiteSpace: "nowrap", fontSize: '14px'}}>{value}</div>
                }
            }, {
                name: "number",
                label: "Número",
                options: {
                    customBodyRender: value => <div style={{whiteSpace: "nowrap", fontSize: '14px'}}>{value}</div>
                }
            }, {
                name: "issueDate",
                label: "Emitido em",
                options: {
                    display: "false",
                    customBodyRender: value => <div style={{whiteSpace: "nowrap", fontSize: '14px'}}>{InputUtils.formatDate(value)}</div>
                }
            }, {
                name: "creationDate",
                label: "Criado em",
                options: {
                    customBodyRender: value => <div style={{whiteSpace: "nowrap", fontSize: '14px'}}>{InputUtils.formatDate(value)}</div>
                }
            }, {
                name: "phoneNumber",
                label: "N° Tel.",
                options: {
                    display: "false",
                    customBodyRender: value => <div style={{whiteSpace: "nowrap", fontSize: '14px'}}>{InputUtils.formatPhone(value)}</div>
                }
            },{
                name: "phoneName",
                label: "Nome do contato",
                options: {
                    display: "false",
                    customBodyRender: value => <div style={{whiteSpace: "nowrap", fontSize: '14px'}}>{value}</div>
                }
            },{
                name: "deliveryStatus",
                label: deliveryStatusTruncate,
                options: { 
                    customBodyRender: value => <span style={{whiteSpace: "nowrap", fontSize: '14px'}}>{DeliveryStatus.get(value).description}</span>
                }
            }, {
                name: "actions",
                label: "Ações",
                options: {
                    rowsPerPage: 7,
                    rowsPerPageOptions: [7],
                    filter: false,
                    sort: false,
                    empty: true,
                    viewColumns: false,
                    download: false,
                    customBodyRender: (value, tableMeta) => {
                        return (<div style={{display: "flex"}}>
                            <VisibilityIcon style={{cursor: 'pointer', marginRight: '15px'}} onClick={() => {
                                this.getStubDetails(tableMeta.rowData[0]);
                            }}></VisibilityIcon>
                            <PanoramaIcon style={{cursor: 'pointer', marginRight: '15px'}} onClick={() => {
                                this.getStubImage(tableMeta.rowData[0]);
                            }}>

                            </PanoramaIcon>

                            <PictureAsPdfIcon
                                style={{cursor: 'pointer', marginRight: '15px'}}
                                onClick={async() => {
                                    const props = await this.getStubDetailsForPdf(tableMeta.rowData[0]);
                                    if(props === null) return;
                                    const {latitude, longitude} = props;
                                    let mapImage = "";
                                    let location = "";
                                    if(latitude !== null && longitude !== null) {
                                        mapImage = "https://maps.googleapis.com/maps/api/staticmap?center="+latitude+","+longitude+"&zoom=13&size=900x170&maptype=roadmap"+
                                            "&markers=color:blue%7C"+latitude+","+longitude+
                                            "&key=AIzaSyCrYZ8GU_BGaF_B5OJFLAhjPnIrLacz1k8";
                                        location = await this.getGeolocationForPdf(latitude, longitude);
                                    }
                                    const doc = <StubDetailsPdf details={props} mapImage={mapImage} location={location} />;
                                    const asPdf = pdf([]); // {} is important, throws without an argument
                                    asPdf.updateContainer(doc);
                                    const blob = await asPdf.toBlob();
                                    saveAs(blob, props.accessKey);
                                    this.handleLoadingSpinner(false);
                                }}></PictureAsPdfIcon>

                            <DeleteIcon style={{cursor: 'pointer'}} onClick={() => {
                                let stubId = tableMeta.rowData[0];
                                let companyId = tableMeta.rowData[1];
                                let tipo = tableMeta.rowData[2];
                                let serie = tableMeta.rowData[5];
                                let numero = tableMeta.rowData[6];
                                let cnpj = tableMeta.rowData[4];
                                this.setState({
                                    ...this.state,
                                    selectedStub: [{companyId, stubId, tipo, serie, numero, cnpj}]
                                }, () => this.handleOpenDialog(true));
                            }}>
                            </DeleteIcon>
                        </div>);
                    }
                }
            }
        ];

        const options = {
            searchPlaceholder: 'Pesquisar canhotos',
            download: false,
            customToolbarSelect: (selectedRows, displayData, setselectedrows) => (
                <div style={{margin: "0 15px"}}>
                    <DeleteIcon style={{cursor: 'pointer'}} onClick={() => {
                        let arr = [];
                        const deletableItems = selectedRows.data.map(item => displayData[item.index]);
                        deletableItems.forEach(item => {
                            let stubId = item.data[0];
                            let companyId = item.data[1];
                            let tipo = item.data[2].props.children;
                            let serie = item.data[5].props.children;
                            let numero = item.data[6].props.children;
                            let cnpj = item.data[4].props.children;
                            arr.push({companyId, stubId, tipo, serie, numero, cnpj});
                        });
                        this.setState({
                            ...this.state,
                            selectedStub: arr
                        }, () => this.handleOpenDialog(true));
                    }}>
                    </DeleteIcon>
                </div>
            ),
            customToolbar: () => {
                return (
                    <IconButton aria-label="download" title='Download do CSV'>
                        <CloudDownload style={{ cursor: "pointer" }} onClick={() => this.downloadCSV()} />
                    </IconButton>
                )
            }
        };

        return (
            <Grid
                title={this.generateGridTitle()}
                data={items}
                columns={columns}
                options={options}
            />
        );
    }

    downloadCSV = () => {
        const filters = this.state.filters;
        StubApi.downloadCsv(filters)
            .then(response => {
                let message = response.message;
                if (message.success) {
                    notificationUtils.success(message.details);
                    const fileUtils = new FileUtils();
                    fileUtils.downloadFile('data:application/octet-stream;charset=utf-8;base64,' + response.csv, "canhotos.csv");
                } else {
                    notificationUtils.warn(message.details);
                }
            }).catch(() => {
               notificationUtils.error('Falha ao realizar o download do CSV.');
            });
    }

    generateGridTitle() {
        const phoneNumber = this.state.filters.telephone;
        let title = "Listagem de Canhotos";
        if(!phoneNumber) return title;
        return "Canhotos para o telefone: " + InputUtils.formatPhone(phoneNumber);
    }

    buildDownloadPdf() {
        const {stubDetails} = this.state;

        if(!this.state.stubDetails || !this.state.onDetails || this.state.showLoadingSpinner) {
            return;
        }

        return <div>
            <PictureAsPdfIcon
                style={{cursor: 'pointer', position: "absolute", right: "50px"}}
                onClick={async() => {
                    if(stubDetails === null) return;
                    const {latitude, longitude} = stubDetails;
                    let mapImage = "";
                    let location = "";
                    if(latitude !== null && longitude !== null) {
                        mapImage = "https://maps.googleapis.com/maps/api/staticmap?center="+latitude+","+longitude+"&zoom=13&size=900x170&maptype=roadmap"+
                            "&markers=color:blue%7C"+latitude+","+longitude+
                            "&key=AIzaSyCrYZ8GU_BGaF_B5OJFLAhjPnIrLacz1k8";
                        location = await this.getGeolocationForPdf(latitude, longitude);
                    }
                    const doc = <StubDetailsPdf details={stubDetails} mapImage={mapImage} location={location} />;
                    const asPdf = pdf([]); // {} is important, throws without an argument
                    asPdf.updateContainer(doc);
                    const blob = await asPdf.toBlob();
                    saveAs(blob, stubDetails.accessKey);
                    this.handleLoadingSpinner(false);
                }}></PictureAsPdfIcon>
        </div>;
    }

    buildNewStubDetails() {
        let {documentType, issuerCnpjCpf, series, number, issueDate, receiverName, receiverCnpjCpf, accessKey, creationDate, creationDateTimeOnApp, deliveryStatus, observation, customFieldAnswers } = this.state.stubDetails;

        return (
            <GridDiv item xs={12}>
                <GridDiv container justify="center" spacing={5}>

                    <GridDiv key={1} item xl={6} lg={12}>
                        <h1 className="stub-details__title"> Dados do Canhoto </h1>
                        <table className="stub-details__data">
                            <tr>
                                <th>Tipo de Nota:</th>
                                <td>{InputUtils.formatStubType(documentType)}</td>
                            </tr>
                            <tr>
                                <th>Documento:</th>
                                <td>{InputUtils.formatDocument(issuerCnpjCpf)}</td>
                            </tr>
                            <tr>
                                <th>Série:</th>
                                <td>{series}</td>
                            </tr>
                            <tr>
                                <th>Número:</th>
                                <td>{number}</td>
                            </tr>
                            <tr>
                                <th>Data de emissão:</th>
                                <td>{InputUtils.formatDate(issueDate)}</td>
                            </tr>
                            <tr>
                                <th>Data de upload:</th>
                                <td>{InputUtils.formatFullDate(creationDate)}</td>
                            </tr>
                            <tr>
                                <th>Data da coleta:</th>
                                <td>{InputUtils.formatFullDateWithGMT(creationDateTimeOnApp)}</td>
                            </tr>
                            <tr>
                                <th>Nome do recebedor:</th>
                                <td>{receiverName}</td>
                            </tr>
                            <tr>
                                <th>Documento do recebedor:</th>
                                <td>{InputUtils.formatDocument(receiverCnpjCpf)}</td>
                            </tr>
                            <tr>
                                <th><p style={{textTransform: 'capitalize'}}>{StubProfileUtils.getDeliveryStatusLabel()}</p></th>
                                <td>{DeliveryStatus.get(deliveryStatus).description}</td>
                            </tr>
                            {customFieldAnswers.map(({ name, answer, options }, index) => {
                                const displayAnswer = answer != null ? answer : (options.map(option => option.value).join(', '));

                                return (
                                    <tr key={index}>
                                        <th>{name}</th>
                                        <th>{displayAnswer}</th>
                                    </tr>
                                );
                            })}
                            <tr>
                                <th>Chave de acesso:</th>
                                <td>{accessKey}</td>
                            </tr>                           
                        </table>
                    </GridDiv>
                    <GridDiv key={3} item xs={12}>
                        <h1 className="stub-details__title"> {StubProfileUtils.getObservationLabel()}</h1>
                        {observation}
                    </GridDiv>
                    {this.generateImage()}

                    {this.generateMap()}
                </GridDiv>
            </GridDiv>
        );
    }

    buildNewStubForm() {
        if (this.state.stubDetails === null) return;

        return (
            <div className={'stub__page ' + (this.state.onDetails ? 'stub__page--slide' : '')}>

                {this.buildDownloadPdf()}
                {this.buildNewStubDetails()}

                <ArrowBackIcon className="stub-details__back" onClick={() => this.setState({onDetails: false})}/>
            </div>
        );
    }

    generateImage() {
        if (this.state.stubDetails === null) return;
        let {image} = this.state.stubDetails;
        if(!image) return;


        return (
            <GridDiv key={2} item xl={6} lg={12}>
                <div className="stub-details__image">
                    <h1 className="stub-details__title"> Imagem do Canhoto </h1>
                    <div className="stub-details__content"
                         onMouseOver={() => {
                             return this.handleFullscreenImage(true);
                         }}
                         onMouseLeave={() => {
                             return this.handleFullscreenImage(false);
                         }}
                         onClick={this.download.bind(this)}
                         style={{backgroundImage: 'url("data:image/jpg;base64,' + image + '")'}}>
                    </div>
                </div>
            </GridDiv>
        );
    }

    generateMap() {
        let {latitude, longitude} = this.state.stubDetails;

        if (!latitude || !longitude) return;

        return (
            <GridDiv key={3} item xs={12}>
                <h1 className="stub-details__title"> Mapa da localização de captura </h1>
                <Map
                    google={this.props.google}
                    zoom={12}
                    containerStyle={{width: '100%', height: '250px', position: 'relative'}}
                    initialCenter={{lat: latitude, lng: longitude}}
                    center={{lat: latitude, lng: longitude}}
                >
                    <Marker
                        position={{lat: latitude, lng: longitude}}
                    />
                </Map>
            </GridDiv>
        );
    }

    async download() {
        const a = document.createElement("a");
        a.href = "data:image/jpg;base64," + this.state.stubDetails.image;
        a.download = "canhoto.jpg";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    handleChangeTypeFilter(event) {
        this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                type: {
                    ...this.state.filters.type,
                    [event.target.name]: event.target.checked
                }
            }
        });
    }

    handleChangeFilter(value, filter) {
        this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                [filter]: value
            }
        });
    }

    handleChangeFilterEv(event) {
        this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                [event.target.name]: event.target.value
            }
        });
    }

    handleChangeFilterChecked(event) {
        this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                [event.target.name]: event.target.checked
            }
        });
    }

    handleFilterOpen() {
        this.setState({
            ...this.state,
            showFilter: !this.state.showFilter
        });
    }

    localTheme = {
        ...theme,        
        overrides: {
            ...theme.overrides,

            MuiOutlinedInput: {
                input: {
                    fontSize: '1.3rem',
                    fontWeight: 300,
                    padding: '11.5px 17px'
                }
            },
            MuiButton: {
                label: {
                    fontSize: "14px"
                }
            },
            MuiIconButton: {
                root: {
                    padding: 0
                }
            }
        }
    };


    buildFilters() {
        const {filters, showFilter, isFiltered, onDetails} = this.state;
        const {type, startDate, endDate, serie, number, cnpj, deliveryStatus, searchByRootCnpj} = filters;
        const {nfe, cte, nfce, cfesat, nfse, outros} = type;

        return (
            <ThemeProvider theme={this.localTheme}>
                <div className={"stub-filter " + (onDetails ? " stub-filter__down " : (showFilter ? "" : " stub-filter__disabled "))}>
                    <div className="stub-filter__title" style={{borderRadius: '10px 10px 0px 0px'}}>
                        <div style={{width: '100%'}}  onClick={this.handleFilterOpen.bind(this)}>
                            <h1>Adicionar Filtros</h1>
                        </div>
                        <div className={"stub-filter__clean " + (isFiltered ? "" : "stub-filter__clean--disabled")} onClick={() => this.cleanFilters()} style={{borderRadius: '0px 10px 0px 0px'}}>
                            <ClearIcon className="stub-filter__clean-icon" />
                            <h1>Limpar filtros</h1>
                        </div>
                    </div>
                    <div className="stub-filter__container">
                        <div className="stub-filter__data">
                            <table style={{width: '100%'}}>
                                <tr>
                                    <th>Filtrar por CNPJ raiz?</th>
                                    <td>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={<Checkbox checked={searchByRootCnpj}
                                                                   onChange={(ev) => this.handleChangeFilterChecked(ev)}
                                                                   name="searchByRootCnpj"/>}
                                                label=""
                                            />
                                        </FormGroup>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="stub-filter__data">
                            <table style={{width: '100%'}}>
                                <tr>
                                    <th>Tipo de documento:</th>
                                    <td>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={<Checkbox checked={nfe}
                                                                   onChange={(ev) => this.handleChangeTypeFilter(ev)}
                                                                   name="nfe"/>}
                                                label="NF-e"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={cte}
                                                                   onChange={(ev) => this.handleChangeTypeFilter(ev)}
                                                                   name="cte"/>}
                                                label="CT-e"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={nfce}
                                                                   onChange={(ev) => this.handleChangeTypeFilter(ev)}
                                                                   name="nfce"/>}
                                                label="NFC-e"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={cfesat}
                                                                   onChange={(ev) => this.handleChangeTypeFilter(ev)}
                                                                   name="cfesat"/>}
                                                label="CF-e SAT"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={nfse}
                                                                   onChange={(ev) => this.handleChangeTypeFilter(ev)}
                                                                   name="nfse"/>}
                                                label="NFS-e"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={outros}
                                                                   onChange={(ev) => this.handleChangeTypeFilter(ev)}
                                                                   name="outros"/>}
                                                label="Outros"
                                            />
                                        </FormGroup>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="stub-filter__data">
                            <table className="stub-filter__left">
                                <tr>
                                    <th style={{paddingRight: '54px'}}>CNPJ emitente:</th>
                                    <td>
                                        <TextField fullWidth name="cnpj" value={cnpj} onChange={val => {
                                            val.target.value = InputUtils.formatDocument(val.target.value);
                                            this.handleChangeFilterEv(val);
                                        }} variant="outlined"/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Série:</th>
                                    <td>
                                        <TextField name="serie" value={serie}
                                                   onChange={val => this.handleChangeFilterEv(val)} variant="outlined"/>
                                    </td>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <th>Data de criação:</th>
                                    <td>
                                        <div className="stub-filter__date">
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    disableFuture
                                                    inputVariant="outlined"
                                                    value={startDate}
                                                    onChange={date => this.handleChangeFilter(date, "startDate")}
                                                    format="DD/MM/YYYY"
                                                    maxDate={new Date()}
                                                />
                                                <p>até</p>
                                                <KeyboardDatePicker
                                                    disableFuture
                                                    inputVariant="outlined"
                                                    value={endDate}
                                                    onChange={date => this.handleChangeFilter(date, "endDate")}
                                                    format="DD/MM/YYYY"
                                                    maxDate={new Date()}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Número:</th>
                                    <td>
                                        <TextField name="number" value={number}
                                                   onChange={val => this.handleChangeFilterEv(val)} variant="outlined"/>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="stub-filter__data">
                        <table className="stub-filter__left">
                            <tr>
                                <th>
                                    <p style={{textTransform: 'capitalize'}}>
                                        {<TruncateText text={StubProfileUtils.getDeliveryStatusLabel()} limit={15} />}
                                    </p>
                                </th>
                                <td>
                                    <FormControl variant="outlined" style={{margin: 2, minWidth:420}}>                                       
                                        <Select
                                        value={deliveryStatus}
                                        name="deliveryStatus"
                                        onChange={val => this.handleChangeFilterEv(val)}                          
                                        >
                                            <MenuItem value={null}>Todos</MenuItem>
                                            <MenuItem value={DeliveryStatus.COLLECT.name}>{DeliveryStatus.COLLECT.description}</MenuItem>
                                            <MenuItem value={DeliveryStatus.TOTAL_DELIVERY.name}>{DeliveryStatus.TOTAL_DELIVERY.description}</MenuItem>
                                            <MenuItem value={DeliveryStatus.PARTIAL_DELIVERY.name}>{DeliveryStatus.PARTIAL_DELIVERY.description}</MenuItem>
                                            <MenuItem value={DeliveryStatus.RECIPIENT_NOT_FOUND.name}>{DeliveryStatus.RECIPIENT_NOT_FOUND.description}</MenuItem>
                                            <MenuItem value={DeliveryStatus.RECIPIENT_REFUSED.name}>{DeliveryStatus.RECIPIENT_REFUSED.description}</MenuItem>
                                            <MenuItem value={DeliveryStatus.NONEXISTENT_ADDRESS.name}>{DeliveryStatus.NONEXISTENT_ADDRESS.description}</MenuItem>
                                            <MenuItem value={DeliveryStatus.NOT_DELIVERY_OTHER.name}>{DeliveryStatus.NOT_DELIVERY_OTHER.description}</MenuItem>
                                        </Select>
                                    </FormControl> 
                                </td>
                            </tr>
                        </table>
                        </div>
                        <div className="stub-filter__apply">
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{margin: "13px 18vw 0"}}
                                fullWidth
                                onClick={() => this.filterData(this.state.filters, false)}>
                                Aplicar
                            </Button>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        );
    }

    cleanFilters(){
        this.handleLoadingSpinner(true);
        setTimeout(() => {
            this.setState({
                ...this.state,
                filters: {
                    ...this.initialFilters
                }
            }, () => {
                this.props.loadStubPhone(null);
                this.filterData({
                    ...this.initialFilters,
                    startDate: null,
                    endDate: null
                }, true);
            });

        }, 100);
    }

    filterData(filters, cleaning){
        this.handleLoadingSpinner(true);

        StubApi.filterBy(filters).then(response => {
            const message = response.message;
            if (message.success || message.details === "Nenhum registro foi encontrado com os parâmetros informados.") {
                notificationUtils.success(message.details);
                this.props.loadStubGrid(response.stubs);
                this.setState({
                    showFilter: false,
                    isFiltered: !cleaning,
                });
            } else {
                notificationUtils.warn(message.details);
            }
            this.handleLoadingSpinner(false);
        });
    }

    buildImageBackground() {
        if (this.state.stubDetails == null) return;

        let {image} = this.state.stubDetails;

        return (
            <div>
                <div
                    className={"text-center stub__fullscreen " + (this.state.showFullscreenImage ? "" : "stub__fullscreen--invisible")}>
                    <div className="stub__fullscreen--box">
                        <img src={"data:image/png;base64," + image}/>
                    </div>
                </div>
            </div>
        );
    }
    
    
    handleOpenDialog(value) {
        this.setState({
            ...this.state,
            isDialogOpen: value
        });
    }

    handleOpenDialogImage(value) {
        this.setState({
            ...this.state,
            isDialogOpenImage: value
        });
    }

    buildDialogText(count, selectedStub) {
        let textArray = [];

        selectedStub.forEach(item => {
            textArray.push(<p>
                Canhoto de <b>{item.tipo}</b>, série <b>{item.serie}</b>,
                número <b>{item.numero}</b> e emitente <b>{item.cnpj}</b>
            </p>);
        });

        return textArray;
    }


    buildDialog() {
        const {isDialogOpen, selectedStub} = this.state;
        if(selectedStub == null) return;
        const count = selectedStub.length;

        return (
            <Dialog
                open={isDialogOpen}
                onClose={() => this.handleOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{ "Tem certeza que deseja deletar " + (count == 1 ? "este canhoto?" : "estes canhotos?")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        {this.buildDialogText(count, selectedStub)}

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleOpenDialog(false)}>
                        Voltar
                    </Button>
                    <Button onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            isDialogOpen: false
                                        }, () => {
                                            this.deleteStubList(selectedStub);
                                        });
                                    }} color="primary" autoFocus>
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    buildDialogImage() {
        const { isDialogOpenImage, stubDetails } = this.state
        if (this.state.stubDetails == null) return;
        let {image} = this.state.stubDetails;
        
        console.log(stubDetails)
        return(
            <Vmodal
            title="Imagem do Canhoto"
            isOpen={isDialogOpenImage}
            closeAction={() => this.handleOpenDialogImage(false)}
            componentButton={ <Button onClick={() => this.handleOpenDialogImage(false)} color="primary" variant='contained' size='small'>
            Fechar
        </Button>}
        >
            <GridDiv container xs={12} sm={12} md={12} lg={12} spacing={2} alignItems="center" justifyContent='center'>
                    <img src={"data:image/png;base64," + image} style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }} />
            </GridDiv>
        </Vmodal>
        )
    }

    handleChangeStatusDelivery(event) {
        this.setState({
            ...this.state,
            statusDelivery: event.target.value
        });
    }

    render() {
        return (
            <div>
                {/*{this.buildNewStub()}*/}
                <div className={'table__container ' + (this.state.onDetails ? 'table__container--slide' : '')}>
                    <div>
                        {this.buildGrid()}
                        <LoadingSpinner visible={this.state.showLoadingSpinner}/>
                    </div>
                    <div>
                        {this.buildNewStubForm()}
                    </div>
                </div>
                {this.buildFilters()}
                {this.buildImageBackground()}
                {this.buildDialog()}
                {this.buildDialogImage()}
            </div>
        );
    }
}

StubManage.propTypes = {
    items: PropTypes.array,
    loadStubGrid: PropTypes.func,
    telephone: PropTypes.string
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCrYZ8GU_BGaF_B5OJFLAhjPnIrLacz1k8'
})(StubManage);