import React from 'react';
import App from '../containers/App';
import SigningForm from "../containers/SigningForm";
import TelephoneManage from '../containers/pages/TelephoneManage';
import AppsManage from '../containers/pages/AppsManage';
import * as routes from '../constants/routes';
import StubManage from "../containers/pages/StubManage";
import ConfigManage from "../containers/pages/ConfigManage";
import AboutManage from "../components/pages/AboutManage"
import CustomFieldManage from '../components/pages/CustomFieldManage';
import Pendencies from '../components/pages/Pendencies';

export default {
  private: [
    {
      'path': routes.HOME_PATH,
      'render': (
        <App
          innerContent={
            (<p>&nbsp;</p>)
          }
          title="Início"
        />
      ),
      'exact': true
    },
    {
      'path': routes.TELEPHONE_PATH,
      'render': (
        <App
          innerContent={(<TelephoneManage></TelephoneManage>)}
          title="Administrar Telefones"
          breadCrumbs={["Administrar Telefones"]}
        />
      )
    },
    {
      'path': routes.STUB_PATH,
      'render': (
          <App
              innerContent={(<StubManage></StubManage>)}
              title="Administrar Canhotos"
              breadCrumbs={["Administrar Canhotos"]}
          />
      )
    },
    {
      'path': routes.CUSTOM_FIELD_PATH,
      'render': (
          <App
              innerContent={(<CustomFieldManage></CustomFieldManage>)}
              title="Campos Personalizados"
              breadCrumbs={["Campos Personalizados"]}
          />
      )
    },
    {
      'path': routes.PENDENCIES,
      'render': (
          <App
              innerContent={(<Pendencies></Pendencies>)}
              title="Pendências de Canhoto"
              breadCrumbs={["Pendências de Canhoto"]}
          />
      )
    },
    {
      'path': routes.CONFIGURATION_PATH,
      'render': (
          <App
              innerContent={(<ConfigManage></ConfigManage>)}
              title="Configurações"
              breadCrumbs={["Configurações"]}
          />
      )
    },
    {
      'path': routes.ABOUT_PATH,
      'render': (
          <App
              innerContent={(<AboutManage></AboutManage>)}
              title="Sobre"
              breadCrumbs={["Sobre"]}
          />
      )
    },
    {
      'path': routes.APPS_PATH,
      'render': (
        <App
          innerContent={(<AppsManage></AppsManage>)}
          title="Varitus Apps"
          breadCrumbs={["Varitus Apps"]}
        />
      )
    }
  ],
  public: [
    {
      'path': routes.LOGIN_PATH,
      'render': (
        <SigningForm logoImage="assets/images/logo-canhoto.png" backgroundImagem="assets/images/background.jpg"  />
      )
    }
  ]
};