import axios from 'axios';
import userProfileUtils from "../utils/userProfileUtils";
import * as apiEndpoints from "../constants/apiEndpoints";
import * as global from "../constants/global";
import * as httpMethods from "../constants/httpMethods";
import {userSignOut} from "../actions";

import store from "../../index";

axios.interceptors.response.use((response) => {
    return response;
}, function (error) {

    const originalRequest = error.config;

    if(error.response.data.error_description != null && error.response.data.error_description.includes("Invalid refresh token")){
        store.dispatch(userSignOut());
        return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest._retry) {
        return Promise.reject(error);
    }

    if (error.response.status === 401 && error.response.data.error_description.includes("Access token expired") && !originalRequest._retry) {
        originalRequest._retry = true;

        const TOKEN_ENDPOINT = apiEndpoints.TOKEN_ENDPOINT;

        var user = userProfileUtils.getCurrentUser();
        var formData = new FormData();
        formData.append('grant_type', global.REFRESH_GRANT_TYPE);
        formData.append('refresh_token', user.refreshToken);

        var base64Credentials = btoa(global.AUTH_CLIENT_ID + ':' + global.AUTH_SECRET);
        return axios({
            method: httpMethods.REQUEST_METHOD_POST,
            url: TOKEN_ENDPOINT,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'basic ' + base64Credentials
            },
            data: formData
        }).then((response) => {
            if(response == undefined) {return;}

            var user = userProfileUtils.getCurrentUser();
            user.accessToken = response.data.accessToken;
            user.refreshToken = response.data.refreshToken;

            userProfileUtils.setCurrentUser(user);

            originalRequest.headers.Authorization = ('Bearer ' + response.data.accessToken);
            return axios(originalRequest);
        });
    }

    return Promise.reject(error);
});