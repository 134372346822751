import React, { useState, useEffect, Fragment } from 'react';
import CustomFieldApi from "../../../api/CustomFieldApi";
import Grid from '../../../containers/grid/Grid';
import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LoadingSpinner from '../../../containers/LoadingSpinner';
import dialogDelete from './dialogDelete';

const customFieldListView = (onForm, onEdit) => {

    const [customFields, setCustomFields] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    
    useEffect(() => {
        if(onForm)
            return;

        loadCustomFiels(false);
    }, [onForm])
    
    function loadCustomFiels(showLoader) {
        setShowLoadingSpinner(showLoader);
        CustomFieldApi.getCustomFields()
            .then((response) => {
                let customFields = response.customFields;
                customFields = customFields == null ? [] : customFields;
                setCustomFields(customFields);
                setShowLoadingSpinner(false);
            }
        )
    }

    function handlerDelete(ids) {
        setSelectedIds(ids);
        setOpenDeleteDialog(true);
    }

    function handlerEdit(id) {
        onEdit(id);
    }

    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {},
        },
        {
            name: 'name',
            label: 'Nome',
            options: {},
        },
        {
            name: 'typeDescription',
            label: 'Tipo',
            options: {},
        },
        {
            name: 'defaultValue',
            label: 'Valor Padrão',
            options: {},
        },
        {
            name: "actions",
            label: "Ações",
            options: {
                filter: false,
                sort: false,
                empty: true,
                viewColumns: false,
                download: false,
                customBodyRender: (value, tableMeta) => {
                    return (<div>
                        <Tooltip title='Editar campo'>
                            <EditIcon style={{cursor: 'pointer', marginRight: '6px'}} 
                                      onClick={() => {handlerEdit(tableMeta.rowData[0])}} />
                        </Tooltip>
                        <Tooltip title='Remover campo'>
                            <DeleteIcon style={{cursor: 'pointer', marginRight: '10px'}} 
                                        onClick={() => {handlerDelete([tableMeta.rowData[0]])}} />
                        </Tooltip>
                    </div>);
                }
            }
        }
    ];

    const options = {
        searchPlaceholder: 'Pesquisar campos',
        customToolbarSelect: (selectedRows, displayData) => (
            <div style={{margin: "0 15px"}}>
                <Tooltip title='Remover campos'>
                    <DeleteIcon style={{cursor: 'pointer'}} onClick={() => {
                        let ids = [];
                        const deletableItems = selectedRows.data.map(item => displayData[item.index]);
                        deletableItems.forEach(item => ids.push([item.data[0]]));
                        handlerDelete(ids);
                    }}>
                    </DeleteIcon>
                </Tooltip>
            </div>
        ),
    };

    return (
        <Fragment>
            {dialogDelete(
                openDeleteDialog, 
                selectedIds, 
                () => setOpenDeleteDialog(false),
                () => {
                    setShowLoadingSpinner(true);
                    setOpenDeleteDialog(false);
                },
                () => {
                    setShowLoadingSpinner(false);
                    loadCustomFiels(true)
                },
            )}

            <Grid title={"Listagem de campos personalizados"}
                data={customFields}  
                columns={columns}
                options={options}
            />

            <LoadingSpinner visible={showLoadingSpinner}/>
        </Fragment>
    )
}

export default customFieldListView;