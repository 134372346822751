import React from "react";
import PropTypes from "prop-types";
import { v1 } from "uuid";
import menuConfig from '../config/menu';
import classNames from 'classnames';
import userProfileUtils from "../utils/userProfileUtils";
import '../assets/leftMenu.css';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import InputUtils from "../utils/inputUtils";
import {List, ListItem, ListItemText, MenuItem, Menu, Typography, ListItemIcon} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);
    let expandedMenuItems = (userProfileUtils.getExpandedMenuItem() !== null) ?
    userProfileUtils.getExpandedMenuItem() : [];
    this.state = {
      collapsed: false,
      expandedMenuItems: expandedMenuItems,
    };
  }

  buildMenu = (items) => {
    let menuJsx = [];
    if (items !== undefined) {
      items.forEach(item => {
        menuJsx.push(this.createMenuItem(item, [], item.option));
      });
    }
   
    return menuJsx;
  };

  changePage = (path) => {
    const { changePage } = this.props;

    changePage(path);
    let classList = document.documentElement.classList;
    if (Object.values(classList).includes('sidebar-left-opened')) {
      document.documentElement.classList.remove('sidebar-left-opened');
    }
  };

  toggleExpandedMenuItem(menuItem) {
    let expandedMenuItems = this.state.expandedMenuItems.slice();
    if (expandedMenuItems.includes(menuItem)) {
      var idx = expandedMenuItems.indexOf(menuItem);
      if (idx > -1) {
        expandedMenuItems.splice(idx, 1);
      }
    } else {
      expandedMenuItems.push(menuItem);
    }
    userProfileUtils.setExpandedMenuItem(expandedMenuItems);
    this.setState({
      expandedMenuItems: expandedMenuItems
    });
  }

  createMenuItem = (item, array = [], topLevelMenuItem = null) => {
    const { expandedMenuItems } = this.state;
    let config = menuConfig.menu[item.option];

    if (config == null)
      return null;

    if (item.show === true) {
      if (item.childrens) {
        let rsx = [];

        item.childrens.forEach(element => {
          rsx.push(this.createMenuItem(element, [...array, item.option]));
        });
        let x = (
          <li key={v1()} className={
            classNames({
              'nav-parent': true,
              'nav-expanded': expandedMenuItems.includes(topLevelMenuItem)
            })
          }>
            <a href="javascript:;" onClick={() => this.toggleExpandedMenuItem(topLevelMenuItem)}>
              <i className={config.icon} aria-hidden="true"></i>
              <span>{config.text}</span>
            </a>
            <ul className="nav nav-children"> {rsx} </ul>
          </li>
        );
        return x;
      } else {
        let x = (
          <li key={v1()}>
            <a href="javascript:void(0);" onClick={() => {this.changePage(config.path);}}>             
              <i className={config.icon} aria-hidden="true"></i>
              <span> {config.text} </span>
            </a>
          </li>
        );
        return x;
      }
    }
  };

  buildOverlay = () => {
    const { showOverlay } = this.props;
    let overlayRsx = (<div></div>);
    if (showOverlay === true) {
      overlayRsx = (<div className="overlay"></div>);
    }
    return overlayRsx;
  };

  render() {
    const { logoImage, userImage, items, userSignOut, cnpj, companyName } = this.props;
    const user = userProfileUtils.getCurrentUser();

    if( !user) return null;

    return (
      <div id="box-left-menu">
        {this.buildOverlay()}
        <aside id="sidebar-left" className="sidebar-left sidebar-left-own">
          <div className="sidebar__logo">
            <img src={logoImage} height="97.5" className="logo1" alt="Porto Admin" className="login-logo" />
          </div>
          <div className="sidebar__profile">
            <img src={userImage} height="90" className="username" alt="Porto Admin" className="username-logo" />
            <h1>{user.username.substring(0, user.username.indexOf('@')) }</h1>
            <div className="company__info">
                <h2>{companyName}<br/>{InputUtils.formatDocument(cnpj)}</h2>
                <ExitToAppRoundedIcon className="footer__icon" onClick={() => userSignOut()}/>
            </div>
          </div>
          <div className="nano menu__list">
						<nav
							id="menu"
							className="nav-main"
							role="navigation"
							style={{ height: "auto", overflowY: "auto", padding: "20px 0px 20px 0px" }}
						>
							<ul
								className="nav nav-main menu__item"
								style={{ zIndex: "-1", marginRight: 0 }}
							>
								{this.buildMenu(items)}
							</ul>
						</nav>
					</div>
          {/* <div className="nano menu__list">
            <div className="nano-content">
              <nav id="menu" className="nav-main" role="navigation" style={{ height: "100%", overflowY: "auto", padding: "20px 0" }}>
                <ul className="nav nav-main menu__item" style={{ zIndex: '-1', marginRight: 0 }}>
                  {this.buildMenu(items)}
                </ul>
              </nav>
            </div>
          </div> */}
        </aside>
      </div>
    );
  }
}

LeftMenu.propTypes = {
  items: PropTypes.array.isRequired,
  logoImage: PropTypes.string.isRequired,
  userImage: PropTypes.string.isRequired,
  userSignOut: PropTypes.func.isRequired
};

export default LeftMenu;
