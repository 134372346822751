import { connect } from 'react-redux';
import Grid from '../../components/grid/Grid';

const mapStateToProps = (state, ownProps) => ({
  title: ownProps.title,
  data: ownProps.data,
  columns: ownProps.columns,
  options: ownProps.options
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Grid);
