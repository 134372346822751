/**
 * Action Types
 */

// Configurations
export const CONFIGURATION_MENU_IS_LOADED = 'CONFIGURATION_MENU_IS_LOADED';  
export const TOPBAR_IS_LOADED =  'TOPBAR_IS_LOADED';

// User
export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';

// Telephone
export const TELEPHONE_LIST_IS_LOADED = 'TELEPHONE_LIST_IS_LOADED';

// Stub
export const STUB_LIST_IS_LOADED = 'STUB_LIST_IS_LOADED';
export const STUB_LIST_TELEPHONE = 'STUB_LIST_TELEPHONE';
export const STUB_CONFIGURATION_IS_LOADED = "STUB_CONFIGURATION_IS_LOADED";

// CustomField
export const CUSTOM_FIELD_LIST_IS_LOADED = 'CUSTOM_FIELD_LIST_IS_LOADED';