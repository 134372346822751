import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextValidator } from 'react-material-ui-form-validator';
import InputUtils from "../../utils/inputUtils";

class Input extends Component {

    constructor(props, context) {
        super(props, context);
    }

    generateOnChange(){
        const { onChange, mask } = this.props;

        switch (mask) {
            case "phone":
                return (e) => {
                    e.target.value = InputUtils.formatPhone(e.target.value);
                    return onChange(e);
                };
            case "document":
                return (e) => {
                    e.target.value = InputUtils.formatDocument(e.target.value);
                    return onChange(e);
                };
            case "cnpj":
                return (e) => {
                    e.target.value = InputUtils.formatCnpj(e.target.value);
                    return onChange(e);
                };
            default:
                return (e) => onChange(e);
        }

    }

    render() {

        const inputProps = {
            style: {
                fontSize: 17,
                fontWeight: 400
            },
            ...this.props.inputProps
        };

        const inputLabelProps = {
            style: {
                fontWeight: 300,
                fontSize: 17,
            },
            ...this.props.inputLabelProps
        };

        const formHelperTextProps = {
            style: {
                fontSize: 13
            },
            ...this.props.formHelperTextProps
        };


        const ownProps = {
            ...this.props,
            onChange: this.generateOnChange(),
            inputProps: inputProps,
            InputLabelProps: inputLabelProps,
            FormHelperTextProps: formHelperTextProps
        };

        return (
            <TextValidator {...ownProps} fullWidth/>
        );
    }
}

Input.propTypes = {
    validators: PropTypes.object,
    errorMessages:  PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    inputProps:  PropTypes.object,
    inputLabelProps:  PropTypes.object,
    formHelperTextProps:  PropTypes.object,
    mask: PropTypes.string
};

export default Input;