import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge';

const leftmenu = (state = initialState.leftmenu, action) => {
  switch (action.type) {
    case types.CONFIGURATION_MENU_IS_LOADED: {
      let leftmenu = {
        items: action.items
      };
      return merge(state.leftmenu, leftmenu);
    }        
    default:
      return state;
  }
};
export default leftmenu;
  