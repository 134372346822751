import React from "react";
import PropTypes from "prop-types";
import '../assets/loadingSpinner.css';

const LoadingSpinner = ({ visible }) => {
  let rsx = (<div></div>);
  if (visible) {
    rsx = (
      <div>
        <div className="text-center loading">
          <div className="box">
            <img className="boxImg" src="assets/images/logo.png" />
            <br/>
            <span className="text">
              <i className="fa fa-spinner fa-spin" />&nbsp;Carregando...
            </span>
          </div>
        </div>
      </div>
    );
  }
  return (
    rsx
  );
};

LoadingSpinner.propTypes = {
  visible: PropTypes.bool
};

export default LoadingSpinner;


