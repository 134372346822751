import React, { useEffect, useState } from "react";
import LeftMenu from "../containers/LeftMenu";
import MainContent from "../containers/MainContent";
import PropTypes from "prop-types";
import { AppBar, Toolbar,  Typography,  Grid, Breadcrumbs, Tooltip, IconButton, Avatar} from '@material-ui/core'
import { v1 } from "uuid";
import AplicativosVaritus from "./AplicativosVaritus";
import SupportPop from "./SupportPop";
import * as global from '../constants/global';
import userProfileUtils from "../utils/userProfileUtils";
import StubProfileUtils from "../utils/stubProfileUtils";
import HomologComponent from "./HomologComponent"
import ActuatorApi from "../api/actuatorApi";
import ParametersApi from "../api/parametersApi";

const App = ({ innerContent = null, title = null, breadCrumbs = [] }) => { 

  const [ parameters, setParameters] = useState({})
  const [ enviroment, setEnviroment] = useState()

  useEffect(()=>{ 
      loadParameters()  
  },[]) 

  useEffect(()=>{
    if(parameters.length > 0){
      const foundEnviroment = parameters.find(item => item.key === "ENVIRONMENT");
      setEnviroment(foundEnviroment.value) 
    }
  },[parameters])

  function loadParameters(){
    ParametersApi.getParameters()
    .then(response =>{
      setParameters(response)
    })
  }

  
  
  const [ showVerihTop ,  setShowVerihTop ] = useState( userProfileUtils.getShowVerihTop()) 
  const [ showVerihFlow ,  setShowVerihFlow ] = useState(userProfileUtils.getShowVerihFlow()) 

  window.addEventListener('click', (event) => {
    let loggedUser = userProfileUtils.getCurrentUser();
    if (loggedUser == null) {
      window.location.reload();
    } else {
      let isTokenExpired = userProfileUtils.isTokenExpired();
      if (isTokenExpired) {
        userProfileUtils.clearAll();
        StubProfileUtils.clearAll();
        window.location.reload();
        alert('Sessão expirou.');
      }
    }
  })

  const handleClickSuporte = () => {   
    userProfileUtils.setShowVerihFlow(true)
    userProfileUtils.setShowVerihTop(false)
    if(userProfileUtils.getShowVerihFlow()){
      setShowVerihFlow(true)
    }
    if(userProfileUtils.getShowVerihTop() == false){
      setShowVerihTop(false)
    }
  }



  return (
        <>
        {enviroment == 'STAGE' && <HomologComponent title="Homologação" />}
        <AppBar   
          style={{backgroundColor:'#f2f2f2', paddingBottom:10}}
          position="static"        
          color="inherit" 
          elevation={0} >
          <Toolbar  variant="dense" >         
          <Grid md={3}></Grid> 
          <Grid>
            <Grid>
              <Typography variant="h6" noWrap>
                {title}
              </Typography>
            </Grid>
            <Grid >
              <Breadcrumbs aria-label="breadcrumb">          
                <Typography color="inherit">Home</Typography>
                {breadCrumbs.map((breadCrumb, i) => {
                  return (
                    <Typography  key={v1()} color="inherit">{breadCrumb}</Typography>                 
                    );
                  })}           
              </Breadcrumbs>
            </Grid>
          </Grid>
          <div style={{flexGrow: 1}} />                     
            <AplicativosVaritus/>  
            {
          showVerihTop &&
          <Tooltip title="Mostrar Verih suporte">
            <IconButton onClick={handleClickSuporte}>
              <Avatar alt="Verih Suporte" src="/assets/images/verih.png" style={{ width: 35, height: 35, backgroundColor: '#f3941e', border: '2px solid #ffffff' }} />
            </IconButton>
          </Tooltip>
          } 
          </Toolbar>
        </AppBar>
        <div className="inner-wrapper">
        <LeftMenu logoImage="assets/images/logo.png" userImage="assets/images/default_user.png" />
        <SupportPop url={global.MINHA_CONTA_URL_SUPPORT} avatar='assets/images/verih-head-left.gif' showVerihFlow={showVerihFlow} setShowVerihFlow={setShowVerihFlow} showVerihTop={showVerihTop}  setShowVerihTop={setShowVerihTop} />
        <MainContent
          title={title}
          breadCrumbs={breadCrumbs}
          innerContent={innerContent}
        />
      </div>
      </>
  );
};

App.propTypes = {
  title: PropTypes.string,
  breadCrumbs: PropTypes.array,
  innerContent: PropTypes.object
};

export default App;
