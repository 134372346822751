class DeliveryStatus {

  static NONE = new DeliveryStatus('NONE', '');
  static NO = new DeliveryStatus('NAO', 'Não');
  static YES = new DeliveryStatus('SIM', 'Sim');
  static COLLECT = new DeliveryStatus('COLETA', 'Coleta');
  static TOTAL_DELIVERY = new DeliveryStatus('ENTREGA_TOTAL', 'Entrega Total');
  static PARTIAL_DELIVERY = new DeliveryStatus('ENTREGA_PARCIAL', 'Entrega Parcial');
  static NOT_DELIVERY = new DeliveryStatus('NAO_ENTREGA', 'Não Entregue');
  static NOT_DELIVERY_OTHER = new DeliveryStatus('NAO_ENTREGA_OUTROS', 'Não Entregue - Outros');
  static RECIPIENT_NOT_FOUND = new DeliveryStatus('RECEBEDOR_NAO_ENCONTRADO', 'Recebedor Não Encontrado');
  static RECIPIENT_REFUSED = new DeliveryStatus('RECUSA_DO_RECEBEDOR', 'Recusa do Recebedor');
  static NONEXISTENT_ADDRESS = new DeliveryStatus('ENDERECO_INEXISTENTE', 'Endereço Inexistente');

  static ALL_DELIVERY = [this.YES, this.NO, this.COLLECT, this.TOTAL_DELIVERY, this.PARTIAL_DELIVERY, this.RECIPIENT_NOT_FOUND, this.RECIPIENT_REFUSED, this.NONEXISTENT_ADDRESS, this.NOT_DELIVERY_OTHER];

  constructor(name, description) {
    this.name = name;
    this.description = description;
  }

  static get(name, defaultStatus = DeliveryStatus.NONE) {
    let found = this.ALL_DELIVERY.filter(status => status.name === name)
    if (found.length == 0)
      return defaultStatus;
    return found[0];
  }
}

export default DeliveryStatus;