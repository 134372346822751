import { connect } from "react-redux";
import Input from "../../components/form/Input";

const mapStateToProps = (state, ownProps) => ({
  validators: ownProps.validators,
  errorMessages: ownProps.errorMessages,
  id: ownProps.id,
  label: ownProps.label,
  variant: ownProps.variant,
  fullWidth: ownProps.fullWidth,
  onChange: ownProps.onChange,
  value: ownProps.value,
  inputProps: ownProps.inputProps,
  inputLabelProps: ownProps.inputLabelProps,
  formHelperTextProps: ownProps.formHelperTextProps,
  mask: ownProps.mask
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Input);
