import React from 'react';
import { Route, Redirect } from 'react-router';
import * as routes from '../../constants/routes';

const PublicRoute = ({ isLogged, isTemp, key, path, render, exact }) => {
  return (
    <Route
      key={key}
      path={path}
      render={() => {
          if (isLogged && !isTemp) {
            return <Redirect to={{ pathname: routes.WELCOME_PATH }} />;
          } else {
            return (render);
          }
        }
      }
      exact={exact}
    />
  );
};

export default PublicRoute;
