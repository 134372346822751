import { createTheme } from '@material-ui/core/styles';
import {deepOrange, blue} from '@material-ui/core/colors';

const defaultFontFamily = {
    fontFamily: 'Montserrat, Roboto',
};

const defaultBackgroundColor = {
    backgroundColor: "#F6F6F6"
};

const lightGrey = {
    color: "#606060eb"
};

const theme = createTheme({
    palette: {
        primary: {main: "#FF5B00"},
        secondary: blue
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
        ...defaultFontFamily,
    },
    overrides: {
        MuiTypography: { // Rows per page
            body1: {
                fontSize: 14,
                ...defaultFontFamily,
                fontWeight: 400
            },
            body2: {
                fontSize: 15,
                ...defaultFontFamily,
                fontWeight: 300
            },
            subtitle1: {
                fontSize: 15,
                ...defaultFontFamily,
                fontWeight: 400
            },
            h6: {
                fontSize: '24px',
                ...defaultFontFamily,
                fontWeight: '400',
                lineHeight: '1.6',
                color: '#0a1844b5',
            },
            caption: {
                fontSize: '1.3rem'
            },           
        },
        MuiMenuItem: {
            root: {
                fontSize: 15,
                fontWeight: 300
            }
        },
        MuiTablePagination: {
            input: {
                fontSize: 15,
                ...defaultFontFamily,
                fontWeight: 300
            }
        },
        MuiSelect: {
            icon: {
                fontSize: 15,
                ...defaultFontFamily,
            }
        },
        MUIDataTableSelectCell: {
            headerCell: {
                ...defaultBackgroundColor,
            },
            fixedHeaderCommon: {
                ...defaultBackgroundColor,
            }
        },
        MuiButtonBase: {
            root: {
                fontSize: 15,
                ...defaultFontFamily,
            },
        },
        MuiButton: {
            label: {
                fontSize: "16px",
                fontWeight: 600
            }
        },
        MuiSvgIcon: { // Upper icons
            root: {
                fontSize: 23,
                ...lightGrey,
                lineHeight: 0,
                margin: '-4.25px 0'
            }
        },
        MUIDataTable: { // Table
            paper: {
                boxShadow: "none",
            },
        },
        MUIDataTableBodyCell: { // Cells
            root: {
                ...defaultBackgroundColor,
                fontSize: 17,
                ...defaultFontFamily,
                fontWeight: 400,
                ...lightGrey,
                paddingTop: 0,
                paddingBottom: 0,
                lineHeight: '6.5vh'
            }
        },
        MUIDataTableHeadCell: { // Header
            root: {
                fontSize: 17,
                ...defaultFontFamily,
                color: "#4c4c4c",
                fontWeight: 600
            },
            fixedHeaderCommon: {
                ...defaultBackgroundColor,
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 11
            }
        },
        MuiPaper: {
            root: {
                ...defaultBackgroundColor,
                position: 'relative',
                zIndex: '0'
            }
        },
        MuiTableCell: {
            footer: {
                borderBottom: "none"
            }
        },
        MuiInput: {
            root: {
                fontSize: 22,
                fontweight: 100
            }
        },
        MuiInputBase: {
            input: {
                fontSize: '15px',
                fontWeight: '400',
                '&$disabled': {
                    color: "rgb(152,152,152)"
                }
            }
          },
        MUIDataTableToolbarSelect: {
            title: {
                fontSize: 17,
                ...lightGrey,
                padding: "11px 26px"
            }
        },
        MuiFab: {
            root: {
                float: 'right',
                width: '7.5vh',
                height: '7.5vh'
            }
        },
        PrivateSwitchBase: {
            root: {
                padding: '9px 5px'
            }
        },
        MuiFormControlLabel: {
            root: {
                marginRight: '25px',
                marginLeft: 0,
                marginBottom: 0
            }
        },
        MuiChip: {
            label: {
                fontSize: "15px"
            }
        },
        MuiFormControlLabel: {
            root: {
                marginRight: 0,
                marginLeft: 0,
                marginBottom: 0
            }
        },
        MuiFormControl: {
            root: {
                width: '100%'
            }
        },
        MuiFormGroup: {
          root: {
              display: 'inline'
          }
        },
        MuiFormLabel: {
            root: {
                borderBottom: 'none',
                marginBottom: '5px',
                fontWeight: '300',
                fontSize: '14px',
                color:  "rgba(0, 0, 0, 0.70)"
            }
        },
        MuiDialog: {
            paper: {
                maxWidth: "55vw",
                margin: "15px"
            },
            paperScrollPaper: {
                maxHeight: "calc(100% - 44px)"
            }
        },
        MuiDialogContent: {
          root: {
              overflowY: "hidden"
          }
        },
        MuiDialogTitle: {
            root: {
                width: "100% !important",
                background: "#F6F6F6"
            }
        },
    }
});

export default theme;
  