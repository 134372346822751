import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import axios from 'axios';

class ProductsApi {
  
  static allProducts() {
    const PRODUCTS_ENDPOINT = apiEndpoints.PRODUCTS_ENDPOINT;
    return axios({
      method: httpMethods.REQUEST_METHOD_GET,
      url: PRODUCTS_ENDPOINT,
      headers: {
        'Accept': 'application/json'
      }
    }).then(response => 
      response.data
    ).catch(error =>
      error.response
    );
  }
}
export default ProductsApi;  