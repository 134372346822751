import moment from 'moment';
import initialState from '../reducers/initialState';
import CookiesUtils from './cookiesUtils';
import jwtDecode from "jwt-decode";

class UserProfileUtils {

  static setItem(key, obj) {
    CookiesUtils.setItem(key, JSON.stringify(obj));
  }

  static removeItem(key) {
    CookiesUtils.removeItem(key);
  }

  static getItem(key) {
    let obj = CookiesUtils.getItem(key);
    if (obj !== undefined && obj !== null) {
      return JSON.parse(obj);
    }
    return null;
  }

  static clearAll() {
    this.removeItem('user');
    this.removeItem('currentCompanyCanhotoFacil');
    this.removeItem('supportVerih');
    this.removeItem('varitusLogged')
  }

  static getCurrentCompany() {
    let currentCompany = this.getItem('currentCompanyCanhotoFacil');
    return currentCompany;
  }

  static setCurrentCompany(company) {
    this.setItem('currentCompanyCanhotoFacil', company);
  }

  static getVaritusApiToken() {
    return this.getCurrentCompany().varitusApiToken;
  }

  static getCurrentUser() {
    let currentUser = this.getItem('user');
    return currentUser;
  }

  static setCurrentUser(user) {
    this.setItem('user', user);
  }

  static isUserLogged() {
    let user = this.getCurrentUser();
    return user !== null;
  }

  static isUserAuthorized(productCode) {
    let user = this.getCurrentUser();
    if(user == null) return false;
    let productCodes = user.productCodes.filter(currProductCode => currProductCode === productCode);
    return productCodes.length > 0;
  }

  static isUserTemp() {
    let user = this.getCurrentUser();
    if(user == null) return false;
    return !!user.isTemp;
  }

  static setVerihInitialStatus(status) {
    this.setItem('supportVerih', status);
  }

  static getVerihInitialStatus() {
    return this.getItem('supportVerih');
  }

   // ------------------------------------

  // setShowVerihFlow(false)
  // setShowVerihTop(true)

  static setShowVerihFlow(status) {
    this.setItem('showVerihFlow', status);
  }

  static getShowVerihFlow() {
    return this.getItem('showVerihFlow');
  }

  static setShowVerihTop(status) {
    this.setItem('showVerihTop', status);
  }

  static getShowVerihTop() {
    return this.getItem('showVerihTop');
  }
  // ------------------------------------

  static setExpandedMenuItem(expandedMenuItems) {
    this.setItem('expandedMenuItems', expandedMenuItems);
  }

  static getExpandedMenuItem() {
    return this.getItem('expandedMenuItems');
  }

  static getAccessToken() {
    return this.getCurrentUser().accessToken;
  }

  static getRefreshToken() {
    return this.getCurrentUser().refreshToken;
  }

  static getEnvironmentType() {
    let shared = this.getStateFromLocalStorage('shared');
    return shared.varitusApiEnvType;
  }

  // ----------------------------------------------------
  // setar cookie de logado para vboleto VBOLETO  para ter sincronização de aplicações
  //  ---------------------------------------------------

  static changeCookiesLogged = (varitusLogged) => {
      CookiesUtils.setItem('varitusLogged', JSON.stringify(varitusLogged))    
  }

  //  -----------------------------------------------------

  static isTokenExpired() {
    let accessToken = this.getAccessToken();
    let refreshToken = this.getRefreshToken();
    let isAccessTokenValid = this.isTokenValid(accessToken);
    let isRefreshTokenValid = this.isTokenValid(refreshToken);
    return !isAccessTokenValid && !isRefreshTokenValid;
  }

  static isTokenValid(token) {
    const decoded = jwtDecode(token);
    let nowInSecs = moment().unix();
    let expiresAtInSecs = decoded.exp;
    let valid = nowInSecs < expiresAtInSecs;
    return valid;
  }



  static getStateFromLocalStorage = (key) => {
    switch (key) {
      case 'user':
        return this.getItem(key) || initialState.user;
      case 'shared':
        return this.getItem(key) || initialState.shared;
      default:
        return this.getItem(key) || {};
    }
  }
}
export default UserProfileUtils;
