import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {Switch} from 'react-router';
import {ConnectedRouter} from 'connected-react-router';
import configureStore, {history} from './stub/store/configureStore';
import routes from './stub/config/routes';
import PublicRoute from './stub/containers/routes/PublicRoute';
import PrivateRoute from './stub/containers/routes/PrivateRoute';
import Notification from './stub/containers/Notification';
import userProfileUtils from "./stub/utils/userProfileUtils";
import {loadConfigurations, userSignOut} from './stub/actions';
import {ThemeProvider} from "@material-ui/styles";
import theme from "./stub/Theme";
import moment from "moment";
import 'moment/locale/pt-br';
import { AUTH_PRODUCT_CODE } from './stub/constants/global';

Date.prototype.toJSON = function(){ return moment(this).format("YYYY-MM-DD hh:mm:ss"); };
moment.locale('pt-BR');

const store = configureStore();
const isAuthorized = userProfileUtils.isUserAuthorized(AUTH_PRODUCT_CODE);

if (isAuthorized) {
    store.dispatch(loadConfigurations());
}

render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Notification/>
            <ConnectedRouter history={history}>
                <Switch>
                    {routes.public.map((route, index) => (                    
                        <PublicRoute key={index} path={route.path} render={route.render} exact={route.exact} />
                    ))}
                    {routes.private.map((route, index) => (
                        <PrivateRoute key={index} path={route.path} render={route.render} exact={route.exact} />
                    ))}
                </Switch>
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>, 
    document.getElementById('varitus-app'));


export default store;



