import React from "react";
import PropTypes from "prop-types";
import "../../assets/modal/modal.css";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = () => ({
	white: {
		backgroundColor: "white",
	},
	iconButton: {
		float: "right",
	},
	closeIcon: {
		color: "rgba(0, 0, 0, 0.5)",
	},
	actions: {
		backgroundColor: "#F6F6F6",
	},
});

const generateSaveButton = (onSave, isSaveEnabled, btnName) => {
	return (
		<Button disabled={!isSaveEnabled} onClick={() => onSave()}>
			{btnName? btnName: "Salvar"}
		</Button>
	);
};

const generateLoadingIcon = () => {
	return (
		<CircularProgress
			size={25}
			style={{ color: "white", marginRight: "12px" }}
		/>
	);
};

const ModalSimple = withStyles(styles)((props) => {
	const {
		classes,
		children,
		open,
		content,
		title,
		onClose,
		isLoading,
		onSave,
		isSaveEnabled,
		btnName		
	} = props;

	return (
		<Dialog	
			open={open}
			onClose={onClose}	
		>
			<DialogTitle id="form-title">
				{title}
				<IconButton
					aria-label="close"
					onClick={onClose}
					className={classes.iconButton}
				>
					<CloseIcon className={classes.closeIcon} />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.white}>
				<DialogContentText id="form-description">{content}</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.actions}>
				{isLoading
					? generateLoadingIcon()
					: generateSaveButton(onSave, isSaveEnabled, btnName)}
			</DialogActions>
		</Dialog>
	);
});

ModalSimple.propTypes = {
	open: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isUpdating: PropTypes.bool.isRequired,
	content: PropTypes.object.isRequired,
	title: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	isSaveEnabled: PropTypes.func.isRequired,
};

export default ModalSimple;
