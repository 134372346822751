import React, { Fragment, useState, useEffect} from 'react';
import about from '../../../../package.json';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import actuatorApi from "../../api/actuatorApi";

import { 
    Grid,
    Typography,
    Card,
    Paper
} from "@material-ui/core";

const AboutManage = () => { 

    const [ versionData, setVersionData] = useState({})

    useEffect(()=>{ 
        loadVersion()       
    },[]) 
    
    function loadVersion(){
        actuatorApi.filterVersion()
        .then(response =>{
            setVersionData(response)            
        })
    }   
    
    return( 
            <Fragment>
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Card style={{padding:20, backgroundColor:"#ffff", marginTop:0, maxWidth:650}}>
                        <Paper style={{ marginBottom:5, padding:30,  backgroundColor:"#fff7f2" }}   elevation={0} variant="outlined">                                            
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={3}
                                >                                    
                                <Grid item xs>
                                    <img src="assets/images/logoDark.png" width={350} height={80}/>
                                </Grid>
                                <Grid item xs>
                                    <Typography variant='body2'>
                                        Plataforma web integrada ao aplicativo Canhoto Fácil.
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="h5" style={{ color:"#bdb7b3"}} ><AccountTreeRoundedIcon color="disabled" style={{fontSize:16}} /> Versão Web: {about.version}</Typography>
                                    <Typography variant="h5" style={{ color:"#bdb7b3"}} ><AccountTreeRoundedIcon color="disabled" style={{fontSize:16}} /> Versão API: {versionData.version}</Typography> 
                                </Grid>                                                         
                            </Grid>
                        </Paper>        
                    </Card>
                </Grid>
            </Fragment>             
       );   
}

export default AboutManage;
