import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import Image from '../../../assets/images/no-location-phone.png';

const containerStyle = {
    height: '500px',
};

function CanhotoTelephoneLocation({ latitude, longitude }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCrYZ8GU_BGaF_B5OJFLAhjPnIrLacz1k8',
    });

    const [map, setMap] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    let center = { lat: Number(latitude), lng: Number(longitude) };
    const onLoad = useCallback((map) => {
        if (isMounted) {
            const bounds = new window.google.maps.LatLngBounds(center);
            map.fitBounds(bounds);
            setMap(map);
        }
    }, [isMounted, latitude, longitude]);

    const onUnmount = useCallback(() => {
        if (isMounted) {
            setMap(null);
        }
    }, [isMounted]);

    return isLoaded ? (
        latitude && longitude ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                onLoad={onLoad}
                options={
                    {
                        minZoom: 8,
                        maxZoom: 18,
                    }
                }
                onUnmount={onUnmount}
            >
                <Marker position={center} />
            </GoogleMap>
        ) : (
            <div className={'text-center'}>
                <img className={'text-center'} src={Image} alt="Localização não disponível" style={{width: 250}}/>
                <p className={'text-center'}>Nenhuma localização encontrada</p>
            </div>
        )
    ) : <></>;
}

export default React.memo(CanhotoTelephoneLocation);
