import notificationUtils from "./notificationUtils";

class ApiUtils {
	static list = (handleLoader, api, loadItems) => {
		handleLoader(true);
		api
			.list()
			.then((response) => {
				let message = response.message;
				if (!message.success) {
					notificationUtils.warn(message.details);
				}
				handleLoader(false);
				loadItems(response);
			})
			.catch(() => {
				handleLoader(false);
				notificationUtils.error("API do Canhoto indisponível!");
			});
	};

	static get = (handleLoader, api, loadItems, id) => {
		handleLoader(true);
		api
			.get(id)
			.then((response) => {
				let message = response.message;
				if (!message.success) {
					notificationUtils.warn(message.details);
				}
				loadItems(response);
			})
			.catch((ex) => {
				handleLoader(false);
				notificationUtils.error("API do Canhoto indisponível!");
			});
	};

	static delete = (handleLoader, api, messages, id, loadItems) => {
		handleLoader(true);
		api
			.delete(id)
			.then((response) => {
				let message = response.message;
				if (message.success) {
					notificationUtils.success(messages.success.delete);
					this.list(handleLoader, api, loadItems);
				} else {
					notificationUtils.warn(message.details);
					handleLoader(false);
				}
			})
			.catch(() => {
				handleLoader(false);
				notificationUtils.error("API de Canhoto indisponível!");
			});
	};

	static save = (handleFormLoader, api, messages, item, handleCloseForm) => {
		handleFormLoader(true);
		api
			.save(item)
			.then((response) => {
				let message = response.message;
				if (message.success) {
					notificationUtils.success(messages.success.new);
					handleCloseForm();
				} else {
					notificationUtils.warn(message.details);
					handleFormLoader(false);
				}
			})
			.catch(() => {
				handleFormLoader(false);
				notificationUtils.error("API de Canhoto indisponível!");
			});
	};

	static update = (handleFormLoader, api, messages, item, handleCloseForm) => {
		handleFormLoader(true);
		api
			.update(item)
			.then((response) => {
				let message = response.message;
				if (message.success) {
					notificationUtils.success(messages.success.update);
					handleCloseForm();
				} else {
					notificationUtils.warn(message.details);
					handleFormLoader(false);
				}
			})
			.catch(() => {
				handleFormLoader(false);
				notificationUtils.error("API de Canhoto indisponível!");
			});
	};

	static custom = (handleLoader, postExecute, api) => {
		handleLoader(true);
		api()
			.then((response) => {
				let message = response.message;
				if (!message.success) {
					notificationUtils.warn(message.details);
				} else {
					notificationUtils.success(message.details);
				}
				postExecute();
				handleLoader(false);
			})
			.catch(() => {
				handleLoader(false);
				notificationUtils.error("API do Canhoto indisponível!");
			});
	};

	static execute = (api, onSuccess, onWarning, onError) => {
		api()
			.then((response) => {
				let message = response.message;
				if (message.success) {
					onSuccess.forEach(element => {
						element(response);
					});
				} else {
					onWarning.forEach(element => {
						element(response);
					});
				}
				return response;
			})
			.catch(() => {
				notificationUtils.error("API do Canhoto indisponível!");
			});
	}
}

export default ApiUtils;
