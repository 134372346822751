import React, {Component} from 'react';
import {Document, Page, Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import InputUtils from "../../utils/inputUtils";
import DeliveryStatus from '../../constants/deliveryEnum';
import StubProfileUtils from '../../utils/stubProfileUtils';
import TruncateText from '../TruncateText';

class StubDetailsPdf extends Component {

    constructor(props){
        super(props);
    }

    styles = StyleSheet.create({
        page: {
            color: "#777",
        },
        pageMargin: {
            margin: "5% 10%",
            marginTop: "10%",
            fontFamily: "Helvetica",
            paddingTop: "17pt"
        },
        titleSection: {
            width: "100%"
        },
        title: {
            fontSize: "22pt",
            color: "#5b5b75",
            textAlign: "center",
            paddingBottom: "15pt",
            fontFamily: "Helvetica-Bold"
        },
        dataSection: {
            flexDirection: "row",
            paddingBottom: "4pt",
            margin: "0 45pt"
        },
        textTitle: {
            fontSize: "13pt",
            flexGrow: 2,
            fontFamily: "Helvetica-Bold"
        },
        textData: {
            fontSize: "13pt",
        },
        textAccessKey: {
            fontSize: "13pt",
            textAlign: "center"
        },
        textObservation: {
            fontSize: "13pt",
            textAlign: "center"
        },
        footer: {
            position: "absolute",
            bottom: 0,
            paddingBottom: "10pt",
            width: "100%"
        },
        header: {
            position: "absolute",
            top: 0,
            paddingTop: "10pt",
            width: "100%"
        },
        imageStyle: {
            maxHeight: "150pt",
            objectFit: "contain"
        }
    });

    render () {
        let {details, mapImage, location} = this.props;
        let {documentType, issuerCnpjCpf, series, number, issueDate, receiverName, receiverCnpjCpf, accessKey, creationDate, creationDateTimeOnApp, image, deliveryStatus, observation, customFieldAnswers } = details;
        let {page, title, textTitle, textData, titleSection, dataSection, pageMargin, textAccessKey, textObservation, footer, header, imageStyle} = this.styles;
        let fullImage = "data:image/jpg;base64," + image;

        return <Document>
            <Page size="A4" wrap={false} style={page}>
                <View style={header}>
                    <Image src={window.location.origin + "/assets/images/header.jpg"} allowDangerousPaths={true}></Image>
                </View>
                <View style={pageMargin}>
                    <View style={titleSection}>
                        <Text style={title}>
                            Dados do Canhoto
                        </Text>
                    </View>
                    <View style={dataSection}>
                        <Text style={textTitle}>Tipo de Nota</Text>
                        <Text style={textData}>{InputUtils.formatStubType(documentType)}</Text>
                    </View>
                    <View style={dataSection}>
                        <Text style={textTitle}>Documento</Text>
                        <Text style={textData}>{InputUtils.formatDocument(issuerCnpjCpf)}</Text>
                    </View>
                    <View style={dataSection}>
                        <Text style={textTitle}>Série</Text>
                        <Text style={textData}>{series}</Text>
                    </View>
                    <View style={dataSection}>
                        <Text style={textTitle}>Número</Text>
                        <Text style={textData}>{number}</Text>
                    </View>
                    <View style={dataSection}>
                        <Text style={textTitle}>Data de emissão</Text>
                        <Text style={textData}>{InputUtils.formatDate(issueDate)}</Text>
                    </View>
                    <View style={dataSection}>
                        <Text style={textTitle}>Data de upload</Text>
                        <Text style={textData}>{InputUtils.formatFullDate(creationDate)}</Text>
                    </View>
                    <View style={dataSection}>
                        <Text style={textTitle}>Data da coleta</Text>
                        <Text style={textData}>{InputUtils.formatFullDateWithGMT(creationDateTimeOnApp)}</Text>
                    </View>
                    <View style={dataSection}>
                        <Text style={textTitle}>Nome do recebedor</Text>
                        <Text style={textData}>{receiverName}</Text>
                    </View>
                    <View style={dataSection}>
                        <Text style={textTitle}>Documento do recebedor</Text>
                        <Text style={textData}>{InputUtils.formatDocument(receiverCnpjCpf)}</Text>
                    </View>
                    <View style={dataSection}>
                        <Text style={textTitle}>{StubProfileUtils.getDeliveryStatusLabel()}</Text>
                        <Text style={textData}>{DeliveryStatus.get(deliveryStatus).description}</Text>
                    </View>
                    <View style={{marginTop: "5pt"}}></View>

                    {customFieldAnswers.map(({ name, answer, options }, index) => {
                        const displayAnswer = answer != null ? answer : (options.map(option => option.value).join(', '));

                        return (
                            <View key={index} style={dataSection}>
                            <Text style={textTitle}>{name}</Text>
                            <Text style={textData}>{displayAnswer}</Text>
                            </View>
                        );
                    })}

                    <View style={{marginTop: "5pt"}}>
                        <Text style={[textAccessKey, {fontFamily: "Helvetica-Bold"}]}>Chave de acesso</Text>
                        <Text style={textAccessKey}>{accessKey}</Text>
                    </View>

                    {observation !== null && observation !== "" &&
                    <View style={[titleSection, {paddingTop: "28pt"}]}>
                        <Text style={title}>
                            {<TruncateText text={StubProfileUtils.getObservationLabel()} limit={15} />}
                        </Text>
                        <Text style={textObservation}>{observation}</Text>
                    </View>}

                    <View style={[titleSection, {paddingTop: "28pt"}]}>
                        <Text style={title}>
                            Imagem do canhoto
                        </Text>
                        <Image src={fullImage} style={imageStyle} allowDangerousPaths={true}></Image>
                    </View>

                    {mapImage !== "" && <View style={[titleSection, {paddingTop: "28pt"}]}>
                        <Text style={title}>
                            Mapa
                        </Text>
                        <Image src={mapImage} allowDangerousPaths={true}></Image>
                        <Text style={[textAccessKey, {paddingTop: "10pt"}]}>{location}</Text>
                    </View>}
                </View>
                <View style={footer}>
                    <Image src={window.location.origin + "/assets/images/footer.jpg"} allowDangerousPaths={true}></Image>
                </View>
            </Page>
        </Document>;
    }

}

StubDetailsPdf.propTypes = {
};

export default StubDetailsPdf;