import { combineReducers } from 'redux';
import topbar from './topbar';
import leftmenu from './leftmenu';
import telephone from './telephone';
import user from './user';
import stub from './stub';

import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
  router: connectRouter(history),
  topbar,
  leftmenu, 
  telephone,
  stub,
  user
});
