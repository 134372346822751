import React, { useEffect, useState } from "react";
import { 
    IconButton,
    Typography,
    Grid,
    Box,
    Popover,
    Link,
    Tooltip,
    Paper
} from '@material-ui/core'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import AppsRoundedIcon from '@material-ui/icons/AppsRounded';
import { makeStyles } from '@material-ui/core/styles';
import productsApi from '../api/productsApi';
import TruncateText from "./TruncateText";


const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 40,
        height: 45,
    },
    app: {        
      minWidth: 380,
      maxWidth: 380,
      minHeight: 350,      
      padding: 20,
      backgroundColor: "#ffffff",

  },
  btnApp: {        
      padding: 10,
      minWidth: 110,
      minHeight: 85,
      maxHeight: 85,
      textAlign: 'center',
      marginTop: 5,
      marginBottom: 10,
      backgroundColor: "#ffffff",
      "&:hover": {
          cursor: 'pointer',
          backgroundColor: '#f1f1f1',
      },
  },
    btnLink:{  
        "&:link":{
            color:'#000000',      
            textDecoration:'none',
        },
        "&:visited":{
            color:'#000000', 
            textDecoration:'none',
        },
        "&:hover":{
            color:'#000000',
            textDecoration:'none',
        },
        "&:active":{
            color:'#000000', 
            textDecoration:'none',
        }       
    }
}));

function AplicativosVaritus() {
  
  const classes = useStyles(); 
  const [ products, setProducts] = useState([]) 

  useEffect(()=>{
    productsApi
    .allProducts()
    .then(response => {
      setProducts({   
        ...products,                   
        products: response.products
      })    
    })  
  },[])
  
  const generateApps = () =>{  
     const apps =  products.products || [];
     const arr = []     
     let appsFiltered = apps.filter(value => (value.productCode != "VPIX") && (value.productCode != "STUB_API"));
     appsFiltered.forEach(product => {
      arr.push(generateApp(product.logo, product.url, product.description));
     })  
    return arr;
  }

  const generateApp = (img, url, name) => {      
      return (
        <Tooltip title={`${name}`}>
          <Paper elevation={0} className={classes.btnApp}>
            <Grid container direction="column" alignItems="center" justifyContent="center" >
                    <Link href={url} target="_blank" color="inherit" className={classes.btnLink}>
                        <Box style={{ textAlign: 'center' }}>
                            <img className={classes.avatar} src={img} />
                        </Box>
                        <Box style={{ textAlign: 'center' }}>
                          <TruncateText text={name} limit={12} />
                        </Box>
                    </Link>
            </Grid>
          </Paper>
        </Tooltip>
      )
  }
  
  return (
     <PopupState variant="popover" popupId="demo-popup-popover">
       {(popupState) => (
         <div>  
           <Tooltip title="Varitus APP">
             <IconButton
                 aria-label="open drawer"
                 color="inherit"                
                 edge='end'
                 {...bindTrigger(popupState)}
               >
                 <AppsRoundedIcon />
             </IconButton>
           </Tooltip>                 
           <Popover                    
             {...bindPopover(popupState)}
             anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
             }}
             transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
             }}
           >
             <Paper elevation={0} className={classes.app} >
                <Grid container xs={12} md={12} sm={12} direction="row"
                    alignItems="center">
                    {generateApps()}
                </Grid>
            </Paper>
           </Popover>
         </div>
       )}
     </PopupState>
   )
}


export default AplicativosVaritus