/**
 * Global constants
 */

// Varitus API
export const VARITUS_API_PRODUCTION_ENV = '1';
export const VARITUS_API_STAGING_ENV= '2';

// Global
export const APP_LOCALE = "pt-br";

// URLs
export const AUTH_HOST = process.env.REACT_APP_HOST_AUTH;
export const STUB_HOST = process.env.REACT_APP_HOST_STUB;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN ;

export const AUTHORIZATION_SERVER_URL = AUTH_HOST ;
export const STUB_PHONE_API_URL = STUB_HOST + '/companiesPhones';
export const STUB_API_URL = STUB_HOST + '/stub';

// Pagination
export const MAX_RECORDS_PER_PAGE = 12;

// JWT Authentication
export const AUTH_CLIENT_ID = 'VARITUS_WEB';
export const AUTH_PRODUCT_CODE = 'STUB_API';
export const AUTH_SECRET = 'tZLd9Zlc4ZGVPU';
export const AUTH_GRANT_TYPE = 'password';

export const REFRESH_GRANT_TYPE = 'refresh_token'

export const STUB_API_SECRET = '6C7SKSLCEjZxvz'


// Minha Conta - Support 
export const MINHA_CONTA_URL_SUPPORT = process.env.REACT_APP_HOST_MINHA_CONTA + '/#/account/support';
