import { connect } from "react-redux";
import Notification from "../components/Notification";

const mapStateToProps = (state, ownProps) => ({
    autoClose: ownProps.autoClose,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
