import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import userProfileUtils from "../utils/userProfileUtils";
import axios from 'axios';
import React from "react";
import StubFilterUtils from "../utils/stubFilterUtils";

class StubApi {

    static downloadCsv(filter = {}) {
        let user = userProfileUtils.getCurrentUser();
        let company = userProfileUtils.getCurrentCompany();
        let document = company.cnpj;
        let token = user.accessToken;

        let data = {
            "companyCnpj": document,
            ...StubFilterUtils.formatCsvFilter(filter)
        };
        
        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: apiEndpoints.STUB_CSV_DOWNLOAD_ENDPOINT,
            headers: {'Accept': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token},
            params: data
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static filterBy(filter = {}) {
        let user = userProfileUtils.getCurrentUser();
        let company = userProfileUtils.getCurrentCompany();
        let document = company.cnpj;
        let token = user.accessToken;

        let data = {
            "companyCnpj": document,
            ...StubFilterUtils.formatFilter(filter)
        };

        return axios({
            method: httpMethods.REQUEST_METHOD_POST,
            url: apiEndpoints.STUB_LIST_ENDPOINT,
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token},
            data: JSON.stringify(data)
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static getStubProfile() {
        let user = userProfileUtils.getCurrentUser();
        let company = userProfileUtils.getCurrentCompany();
        let document = company.cnpj;
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: apiEndpoints.STUB_PROFILE_ENDPOINT,
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token}
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static deleteStub(companyId, stubId) {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_DELETE,
            url: apiEndpoints.STUB_DELETE_ENDPOINT(companyId, stubId),
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token}
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 403 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static getDetails(stubId) {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: apiEndpoints.STUB_DETAILS_ENDPOINT + '/' + stubId,
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token}
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 403 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

}

export default StubApi;